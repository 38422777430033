export const FETCH_REPORT_BY_DOCTOR_REQUEST = "FETCH_REPORT_BY_DOCTOR_REQUEST";
export const FETCH_REPORT_BY_DOCTOR_SUCCESS = "FETCH_REPORT_BY_DOCTOR_SUCCESS";
export const FETCH_REPORT_BY_DOCTOR_FAIL = "FETCH_REPORT_BY_DOCTOR_FAIL";

export const FETCH_REPORT_BY_TEST_REQUEST = "FETCH_REPORT_BY_TEST_REQUEST";
export const FETCH_REPORT_BY_TEST_SUCCESS = "FETCH_REPORT_BY_TEST_SUCCESS";
export const FETCH_REPORT_BY_TEST_FAIL = "FETCH_REPORT_BY_TEST_FAIL";

export const FETCH_LAB_REPORT_REQUEST = "FETCH_LAB_REPORT_REQUEST";
export const FETCH_LAB_REPORT_SUCCESS = "FETCH_LAB_REPORT_SUCCESS";
export const FETCH_LAB_REPORT_FAIL = "FETCH_LAB_REPORT_FAIL";


export const FETCH_LAB_REPORT_PROFIT_REQUEST = "FETCH_LAB_REPORT_PROFIT_REQUEST";
export const FETCH_LAB_REPORT_PROFIT_SUCCESS = "FETCH_LAB_REPORT_PROFIT_SUCCESS";
export const FETCH_LAB_REPORT_PROFIT_FAIL = "FETCH_LAB_REPORT_PROFIT_FAIL";
