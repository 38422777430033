import React from "react";

const ProfitReportPage = ({ profits }) => {
  return (
    <table className=" mx-auto bg-white mt-5">
      <thead>
        <tr>
          <th className="py-2 border-b">#</th>
          <th className="py-2 border-b">.....</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Expenses</td>
          <td className="font-bold text-xl">{profits?.expenses?.toLocaleString()}</td>
        </tr>

        <tr>
          <td>Invoices</td>
          <td className="font-bold text-xl">{profits?.invoices?.toLocaleString()}</td>
        </tr>

        <tr>
          <td>Paybacks</td>
          <td className="font-bold text-xl">{profits?.paybacks?.toLocaleString()}</td>
        </tr>

        <tr>
          <td>Purchased</td>
          <td className="font-bold text-xl">{profits?.purchased?.toLocaleString()}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ProfitReportPage;
