import React, { useEffect, useState } from "react";
import { MdOutlineSaveAlt } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  createSuplierAction,
  updateSuplierAction,
} from "../../Actions/LabrotoryAction/SuplierAction";

const CreatePaybackModal = ({ userId, onClose, itemList }) => {
  const [inputValue, setInputValue] = useState({
    id: "",
    note: "",
    amount: "",
  });
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (itemList?.length > 0) {
      const item = itemList[0];
      setInputValue({
        id: item.id,
        amount: item.amount,
        note: item.note,
      });
    }
  }, [itemList]);

  const validate = () => {
    const errors = {};
    if (!inputValue.amount) {
      errors.amount = "Amount is required";
    } else if (isNaN(inputValue.amount) || Number(inputValue.amount) <= 0) {
      errors.amount = "Amount must be a valid positive number";
    }
    if (!inputValue.note) {
      errors.note = "Note is required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      if (inputValue.id) {
        dispatch(
          updateSuplierAction(
            inputValue.id,
            inputValue.amount,
            inputValue.note,
            userId
          )
        );
      } else {
        dispatch(
          createSuplierAction(inputValue.amount, inputValue.note, userId)
        );
      }
      // Clear the form
      setInputValue({
        id: "",
        amount: "",
        note: "",
      });
      onClose();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <div>
            <label htmlFor="amount">Amount </label>
          </div>
          <input
            type="text"
            id="amount"
            className="input w-full"
            name="amount"
            value={inputValue.amount}
            onChange={handleChange}
          />
          {errors.amount && (
            <span className="text-red-500">{errors.amount}</span>
          )}
        </div>

        <div className="flex flex-col mt-3">
          <div>
            <label htmlFor="note">Note </label>
          </div>
          <textarea
            id="note"
            className="input w-full"
            name="note"
            value={inputValue.note}
            onChange={handleChange}
          ></textarea>
          {errors.note && (
            <span className="text-red-500">{errors.note}</span>
          )}
        </div>

        <button
          type="submit"
          className="btn-primary flex gap-2 items-center mt-3"
        >
          Save <MdOutlineSaveAlt />
        </button>
      </form>
    </div>
  );
};

export default CreatePaybackModal;
