export const CREATE_TEST_REQUEST = "CREATE_TEST_REQUEST";
export const CREATE_TEST_SUCCESS = "CREATE_TEST_SUCCESS";
export const CREATE_TEST_FAIL = "CREATE_TEST_FAIL";

export const FETCH_TEST_REQUEST = "FETCH_TEST_REQUEST";
export const FETCH_TEST_SUCCESS = "FETCH_TEST_SUCCESS";
export const FETCH_TEST_FAIL = "FETCH_TEST_FAIL";

export const UPDATE_TEST_REQUEST = "UPDATE_TEST_REQUEST";
export const UPDATE_TEST_SUCCESS = "UPDATE_TEST_SUCCESS";
export const UPDATE_TEST_FAIL = "UPDATE_TEST_FAIL";

export const FETCH_TEST_CATEGORY_REQUEST = "FETCH_TEST_CATEGORY_REQUEST";
export const FETCH_TEST_CATEGORY_SUCCESS = "FETCH_TEST_CATEGORY_SUCCESS";
export const FETCH_TEST_CATEGORY_FAIL = "FETCH_TEST_CATEGORY_FAIL";

export const CREATE_TEST_CATEGORY_REQUEST = "CREATE_TEST_CATEGORY_REQUEST";
export const CREATE_TEST_CATEGORY_SUCCESS = "CREATE_TEST_CATEGORY_SUCCESS";
export const CREATE_TEST_CATEGORY_FAIL = "CREATE_TEST_CATEGORY_FAIL";


export const DELETE_TEST_CATEGORY_REQUEST = "DELETE_TEST_CATEGORY_REQUEST";
export const DELETE_TEST_CATEGORY_SUCCESS = "DELETE_TEST_CATEGORY_SUCCESS";
export const DELETE_TEST_CATEGORY_FAIL = "DELETE_TEST_CATEGORY_FAIL";



export const CREATE_MANGE_TEST_REQUEST = "CREATE_MANGE_TEST_REQUEST";
export const CREATE_MANGE_TEST_SUCCESS = "CREATE_MANGE_TEST_SUCCESS";
export const CREATE_MANGE_TEST_FAIL = "CREATE_MANGE_TEST_FAIL";

export const FETCH_MANGE_TEST_REQUEST = "FETCH_MANGE_TEST_REQUEST";
export const FETCH_MANGE_TEST_SUCCESS = "FETCH_MANGE_TEST_SUCCESS";
export const FETCH_MANGE_TEST_FAIL = "FETCH_MANGE_TEST_FAIL";

export const UPDATE_MANGE_TEST_REQUEST = "UPDATE_MANGE_TEST_REQUEST";
export const UPDATE_MANGE_TEST_SUCCESS = "UPDATE_MANGE_TEST_SUCCESS";
export const UPDATE_MANGE_TEST_FAIL = "UPDATE_MANGE_TEST_FAIL";


