import {
  FETCH_MANGE_TEST_FAIL,
  FETCH_MANGE_TEST_REQUEST,
  FETCH_MANGE_TEST_SUCCESS,
  FETCH_TEST_CATEGORY_FAIL,
  FETCH_TEST_CATEGORY_REQUEST,
  FETCH_TEST_CATEGORY_SUCCESS,
  FETCH_TEST_FAIL,
  FETCH_TEST_REQUEST,
  FETCH_TEST_SUCCESS,
} from "../../ActionType/LabrotoryTypes/TestType";

export const getTestReducer = (
  state = { loading: true, tests: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_TEST_REQUEST:
      return {
        loading: true,
      };
    case FETCH_TEST_SUCCESS:
      return {
        loading: false,
        tests: action.payload,
        error: "",
      };
    case FETCH_TEST_FAIL:
      return {
        loading: false,
        tests: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const getTestCategoryReducer = (
  state = { loading: true, testCategories: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_TEST_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case FETCH_TEST_CATEGORY_SUCCESS:
      return {
        loading: false,
        testCategories: action.payload,
        error: "",
      };
    case FETCH_TEST_CATEGORY_FAIL:
      return {
        loading: false,
        testCategories: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const getMangeTestsReducer = (
  state = { loading: true, mangeTests: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_MANGE_TEST_REQUEST:
      return {
        loading: true,
      };
    case FETCH_MANGE_TEST_SUCCESS:
      return {
        loading: false,
        mangeTests: action.payload,
        error: "",
      };
    case FETCH_MANGE_TEST_FAIL:
      return {
        loading: false,
        mangeTests: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};
