import React, { useEffect, useState } from "react";
import { LuBadgeDollarSign } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import CreateModals from "../../../Modal/CreateModals";

import { CiEdit } from "react-icons/ci";

import { getSuplierAction } from "../../../Actions/LabrotoryAction/SuplierAction";
import CreateSuplierModal from "../../../Modal/LabrotoryModal/CreateSuplierModal";
import { PiScanThin } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const SuplierPage = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);

  const getSuplier = useSelector((state) => state.getSuplier);
  const { supliers } = getSuplier;

  const [itemList, setItemList] = useState([]);
  const addExpenseToList = (test) => {
    setItemList((prevList) => {
      if (Array.isArray(prevList)) {
        return [...prevList, test];
      }
      return [test];
    });
  };
  useEffect(() => {
    dispatch(getSuplierAction());
    // dispatch(getMangeTestAction());
  }, []);
  const calculateDebt = (supliers) => {
    return supliers?.map((suplier) => {
      const totalPurchase = suplier?.purchase?.reduce(
        (acc, purchase) => acc + parseFloat(purchase.amount || 0),
        0
      );
      const totalPayback = suplier?.payback?.reduce(
        (acc, pay) => acc + parseFloat(pay.amount || 0),
        0
      );
      const debt = totalPayback - totalPurchase;
      return {
        ...suplier,
        debt,
      };
    });
  };

  const updatedSupliers = calculateDebt(supliers);
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  return (
    <div className="parent p-2 ">
      <div className="flex gap-2 items-center justify-between">
        <div className="flex gap-2 items-center text-blue-800 font-bold text-lg">
          <div>
            <PiScanThin />
          </div>
          <p>Suplier</p>
        </div>

        <div>
          <button
            onClick={() => setIsOpenCreateModal(true)}
            className="btn-primary flex gap-2 items-center "
          >
            Add Suplier <PiScanThin />
          </button>
        </div>
      </div>
      <table className="mt-2">
        <thead className="">
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Debt</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {updatedSupliers?.map((suplier, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{suplier.name}</td>
                <td>{suplier.phone}</td>
                <td>{suplier.address}</td>
                <td
                  onClick={() =>
                    navigate("/eachSuplier", { state: { suplier: suplier } })
                  }
                  className="underline text-red-600 font-bold text-lg cursor-pointer"
                >
                  {formatNumber(suplier.debt)}
                </td>
                <td>
                  <div
                    onClick={() => {
                      setIsOpenCreateModal(true);
                      addExpenseToList(suplier);
                    }}
                    className="flex gap-2 items-center"
                  >
                    <button className="btn-info flex gap-2 items-center">
                      update <CiEdit />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <CreateModals
        isOpen={isOpenCreateModal}
        onClose={() => {
          setIsOpenCreateModal(false);
          setItemList([]);
        }}
        head={itemList.length > 0 ? "Update Suplier" : "Add Suplier"}
      >
        <CreateSuplierModal
          userId={user.id}
          onClose={() => setIsOpenCreateModal(false)}
          itemList={itemList}
        />
      </CreateModals>
    </div>
  );
};

export default SuplierPage;
