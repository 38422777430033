import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createMangeTestAction,
  updateMangeTestAction,
} from "../../../Actions/LabrotoryAction/TestAction";

const CreateMangeTestsModal = ({ testCategories, onClose, mangeTestLisit }) => {
  const [inputValue, setInputValue] = useState({
    id: "",
    name: "",
    testId: "",
    unit: "",
    price: "",
    cost: "",
    instrument: "",
    normal: "",
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (mangeTestLisit?.length > 0) {
      console.log(mangeTestLisit);
      mangeTestLisit?.map((mange) => {
        setInputValue({
          ...inputValue,
          id: mange.id,
          name: mange.name,
          testId: mange.test_category_id,
          unit: mange.unit,
          price: mange.price,
          cost: mange.cost,
          instrument: mange.instrument,
          normal: mange.normal_range,
        });
      });
    } else {
      setInputValue({
        id: "",
        name: "",
        testId: "",
        unit: "",
        price: "",
        cost: "",
        instrument: "",
        normal: "",
      });
    }
  }, [mangeTestLisit]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const validateInputs = () => {
    const newErrors = {};

    if (!inputValue.name) {
      newErrors.name = "Name is required";
    }
    if (!inputValue.testId) {
      newErrors.testId = "Test ID is required";
    }
    if (!inputValue.unit) {
      newErrors.unit = "Unit is required";
    }
    if (!inputValue.price) {
      newErrors.price = "Price is required";
    }
    if (!inputValue.cost) {
      newErrors.cost = "Cost is required";
    }
    if (!inputValue.instrument) {
      newErrors.instrument = "Instrument is required";
    }
    if (!inputValue.normal) {
      newErrors.normal = "Normal range is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputValue.id) {
      if (validateInputs()) {
        dispatch(
          updateMangeTestAction(
            inputValue.id,
            inputValue.name,
            inputValue.testId,
            inputValue.unit,
            inputValue.price,
            inputValue.cost,
            inputValue.instrument,
            inputValue.normal
          )
        );
        onClose();
      }
    } else {
      if (validateInputs()) {
        dispatch(
          createMangeTestAction(
            inputValue.name,
            inputValue.testId,
            inputValue.unit,
            inputValue.price,
            inputValue.cost,
            inputValue.instrument,
            inputValue.normal
          )
        );
        onClose();
      }
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <label htmlFor="Name">Name</label>
            <input
              type="text"
              name="name"
              id="Name"
              placeholder="Name"
              className="input w-full"
              value={inputValue.name}
              onChange={handleInputChange}
            />
            {errors.name && <span className="text-red-500">{errors.name}</span>}
          </div>
          <div className="flex flex-col">
            <label htmlFor="test">Test</label>
            <select
              name="testId"
              className="input w-full"
              id="test"
              value={inputValue.testId}
              onChange={handleInputChange}
            >
              <option value="">Select Test</option>
              {testCategories?.map((test, index) => (
                <option value={test.id} key={index}>
                  {test.name}
                </option>
              ))}
            </select>
            {errors.testId && (
              <span className="text-red-500">{errors.testId}</span>
            )}
          </div>
          <div>
            <label htmlFor="Unit">Unit</label>
            <input
              type="text"
              name="unit"
              id="Unit"
              placeholder="Unit"
              className="input w-full"
              value={inputValue.unit}
              onChange={handleInputChange}
            />
            {errors.unit && <span className="text-red-500">{errors.unit}</span>}
          </div>
          <div>
            <label htmlFor="Price">Price</label>
            <input
              type="number"
              name="price"
              id="Price"
              placeholder="Price"
              className="input w-full"
              value={inputValue.price}
              onChange={handleInputChange}
            />
            {errors.price && (
              <span className="text-red-500">{errors.price}</span>
            )}
          </div>
          <div>
            <label htmlFor="Cost Price">Cost Price</label>
            <input
              type="number"
              name="cost"
              id="Cost Price"
              placeholder="Cost Price"
              className="input w-full"
              value={inputValue.cost}
              onChange={handleInputChange}
            />
            {errors.cost && <span className="text-red-500">{errors.cost}</span>}
          </div>
          <div>
            <label htmlFor="Instrument">Instrument</label>
            <input
              type="text"
              name="instrument"
              id="Instrument"
              placeholder="Instrument"
              className="input w-full"
              value={inputValue.instrument}
              onChange={handleInputChange}
            />
            {errors.instrument && (
              <span className="text-red-500">{errors.instrument}</span>
            )}
          </div>
        </div>
        <div>
          <label htmlFor="norma">Normal Range</label>
          <textarea
            name="normal"
            className="input w-full mt-2"
            placeholder="Normal Range"
            id="norma"
            value={inputValue.normal}
            onChange={handleInputChange}
          ></textarea>
          {errors.normal && (
            <span className="text-red-500">{errors.normal}</span>
          )}
        </div>
        <button type="submit" className="btn-primary mt-4">
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateMangeTestsModal;
