import { FETCH_EXPENSE_FAIL, FETCH_EXPENSE_REQUEST, FETCH_EXPENSE_SUCCESS } from "../../ActionType/LabrotoryTypes/ExpenseType";

export const getExpenseReducer = (
  state = { loading: true, expenses: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_EXPENSE_REQUEST:
      return {
        loading: true,
      };
    case FETCH_EXPENSE_SUCCESS:
      return {
        loading: false,
        expenses: action.payload,
        error: "",
      };
    case FETCH_EXPENSE_FAIL:
      return {
        loading: false,
        expenses: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};
