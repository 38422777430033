import {
  FETCH_INVOICE_FAIL,
  FETCH_INVOICE_REQUEST,
  FETCH_INVOICE_SUCCESS,
  UPDATE_INVOICE_TEST_FAIL,
  UPDATE_INVOICE_TEST_REQUEST,
  UPDATE_INVOICE_TEST_SUCCESS,
} from "../../ActionType/LabrotoryTypes/InvoiceTypes";
import {
  CREATE_PAYBACK_FAIL,
  CREATE_PAYBACK_REQUEST,
  CREATE_PAYBACK_SUCCESS,
  SHOW_PAYBACK_FAIL,
  SHOW_PAYBACK_REQUEST,
  SHOW_PAYBACK_SUCCESS,
  UPDATE_PAYBACK_FAIL,
  UPDATE_PAYBACK_REQUEST,
  UPDATE_PAYBACK_SUCCESS,
} from "../../ActionType/LabrotoryTypes/PaybackType";

//   export const getInvoiceReducer = (
//     state = { loading: true, invoices: [], total: 0, error: "" },
//     action
//   ) => {
//     switch (action.type) {
//       case FETCH_INVOICE_REQUEST:
//         return {
//           loading: true,
//         };
//       case FETCH_INVOICE_SUCCESS:
//         return {
//           loading: false,
//           invoices: action.payload.data,
//           total: action.payload.total,

//           error: "",
//         };
//       case FETCH_INVOICE_FAIL:
//         return {
//           loading: false,
//           invoices: [],
//           error: "Check Your Request",
//         };
//       default:
//         return state;
//     }
//   };

export const createPaybackReducer = (
  state = { loading: true, payback: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_PAYBACK_REQUEST:
      return {
        loading: true,
      };
    case CREATE_PAYBACK_SUCCESS:
      return {
        loading: false,
        payback: action.payload,
        error: "",
      };
    case CREATE_PAYBACK_FAIL:
      return {
        loading: false,
        payback: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const updatePaybackReducer = (
  state = { loading: true, payback: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_PAYBACK_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_PAYBACK_SUCCESS:
      return {
        loading: false,
        payback: action.payload,
        error: "",
      };
    case UPDATE_PAYBACK_FAIL:
      return {
        loading: false,
        payback: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const showPaybackReducer = (
  state = { loading: true, paybacks: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_PAYBACK_REQUEST:
      return {
        loading: true,
      };
    case SHOW_PAYBACK_SUCCESS:
      return {
        loading: false,
        paybacks: action.payload,
        error: "",
      };
    case SHOW_PAYBACK_FAIL:
      return {
        loading: false,
        paybacks: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};
