import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Payback from "./Payback";
import PurchasePage from "./PurchasePage";
import { useDispatch, useSelector } from "react-redux";
import { showPaybackAction } from "../../../Actions/LabrotoryAction/PaybackAction";
import { showPurchaseAction } from "../../../Actions/LabrotoryAction/PurchaseAction";

const EachSuplier = ({ user }) => {
  const location = useLocation();
  const { suplier } = location.state || {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (suplier?.id) {
      dispatch(showPaybackAction(suplier.id));
      dispatch(showPurchaseAction(suplier.id));
    }
  }, [dispatch, suplier]);

  const showPayback = useSelector((state) => state.showPayback);
  const { paybacks } = showPayback;

  const showPurchase = useSelector((state) => state.showPurchase);
  const { purchases } = showPurchase;

  const [activeTab, setActiveTab] = useState("payback");

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  const calculateDebt = (paybacks, purchases) => {
    const totalPurchase = purchases?.reduce((acc, purchase) => acc + parseFloat(purchase.amount), 0);
    const totalPayback = paybacks?.reduce((acc, pay) => acc + parseFloat(pay.amount), 0);
    return totalPayback- totalPurchase ;
  };

  const debt = calculateDebt(paybacks, purchases);

  return (
    <div className="mx-auto p-4 w-full">
      <div className="flex gap-2 justify-between">
        <h2 className="text-2xl font-bold mb-6">Supplier Details</h2>

        <div className="flex justify-center mb-6">
          <button
            className={`px-4 py-2 mx-2 rounded-t-lg ${
              activeTab === "payback"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setActiveTab("payback")}
          >
            Payback
          </button>
          <button
            className={`px-4 py-2 mx-2 rounded-t-lg ${
              activeTab === "purchase"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setActiveTab("purchase")}
          >
            Purchase
          </button>
        </div>
      </div>

      <div className="bg-white shadow w-full rounded-lg p-4">
        {activeTab === "payback" && (
          <Payback
            userId={user?.id}
            suplier={suplier}
            paybacks={paybacks}
            debt={debt}
            formatNumber={formatNumber}
          />
        )}

        {activeTab === "purchase" && (
          <PurchasePage
            userId={user?.id}
            suplier={suplier}
            debt={debt}
            formatNumber={formatNumber}
            purchases={purchases}
          />
        )}
      </div>
    </div>
  );
};

export default EachSuplier;
