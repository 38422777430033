import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAge } from "../../../Actions/getAge";
import AddTestPatientScreen from "./AddTestPatientScreen";

const EditInvoiceScreen = () => {
  const location = useLocation();
  const { invoices } = location.state || {};
  const initialState = {
    id:"",
    fullname: "",
    phone: "",
    birthDate: "",
    gender: "Male",
  };

  const [inputValue, setInputValue] = useState(initialState);
  useEffect(() => {
    setInputValue({
      ...inputValue,
      id:invoices?.patient?.id,
      fullname: invoices?.patient?.fullname,
      phone: invoices?.patient?.phone,
      gender: invoices?.patient?.gender,
      birthDate: invoices?.patient?.birth_date,
    });
  }, [invoices]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleReset = () => {
    setInputValue(initialState);
  };
  const age = getAge(inputValue.birthDate);
  return (
    <>
      <div className="parent pb-4">
        <div className="head">Parent Information</div>

        <div className="container">
          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Full Name
            </label>
            <div>
              <input
                type="text"
                className="input w-full"
                name="fullname"
                value={inputValue.fullname}
                onChange={handleInputChange}
                placeholder="Full Name"
              />
            </div>

            {/* <div className="text-red-600">{errors && errors.fullname}</div> */}
          </div>

          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Phone Number
            </label>
            <div>
              <input
                type="text"
                className="input w-full"
                name="phone"
                value={inputValue.phone}
                onChange={handleInputChange}
                placeholder="Phone Number"
              />
            </div>

            {/* <div className="text-red-600">{errors && errors.phone}</div> */}
          </div>
          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Birth Date *
            </label>
            <div>
              <input
                type="date"
                className="input w-full"
                name="birthDate"
                value={inputValue.birthDate}
                onChange={handleInputChange}
                placeholder="Birth Date"
              />
            </div>
            {/* <div className="text-red-600">{errors && errors.birth_date}</div> */}
          </div>
          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Age *
            </label>
            <div>
              <input
                type="text"
                value={age}
                className="input w-full "
                disabled
                // onChange={handleInputChange}
                placeholder="Age"
              />
            </div>
          </div>

          <div className="parent-input">
            <label htmlFor="gender" className="lbl">
              Gender
            </label>
            <select
              name="gender"
              onChange={handleInputChange}
              className="input"
              value={inputValue.gender}
              id="gender"
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Child">Child</option>
            </select>
            {/* <div className="text-red-600">{errors && errors.gender}</div> */}
          </div>
        </div>
      </div>

      <AddTestPatientScreen
        patientValue={inputValue}
        reset={handleReset}
        invoices={invoices}
        
      />
      <div className="pb-4"></div>
    </>
  );
};

export default EditInvoiceScreen;
