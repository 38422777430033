import Swal from "sweetalert2";
import {
  CREATE_EXPENSE_FAIL,
  CREATE_EXPENSE_REQUEST,
  CREATE_EXPENSE_SUCCESS,
  FETCH_EXPENSE_FAIL,
  FETCH_EXPENSE_REQUEST,
  FETCH_EXPENSE_SUCCESS,
  UPDATE_EXPENSE_FAIL,
  UPDATE_EXPENSE_REQUEST,
  UPDATE_EXPENSE_SUCCESS,
} from "../../ActionType/LabrotoryTypes/ExpenseType";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const getExpenseAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: FETCH_EXPENSE_REQUEST,
  });
  try {
    const resp = await api.get("expense", config);
    const { status, data } = resp;

    if (status == 200) {
      dispatch({
        type: FETCH_EXPENSE_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_EXPENSE_FAIL,
    });
  }
};

export const createExpenseAction =
  (user_id, amount, resoun) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_EXPENSE_REQUEST,
    });
    try {
      const resp = await api.post(
        "expense/create",
        { user_id, amount, resoun },
        config
      );
      const { status, data } = resp;
      dispatch({
        type: CREATE_EXPENSE_SUCCESS,
      });
      if (status == 201) {
        dispatch(getExpenseAction());
        dispatch(getExpenseAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_EXPENSE_FAIL,
      });
    }
  };

export const updateExpenseAction =
  (id, user_id, amount, resoun) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: UPDATE_EXPENSE_REQUEST,
    });
    try {
      const resp = await api.put(
        "expense/update/" + id,
        { user_id, amount, resoun },
        config
      );
      const { status, data } = resp;
      dispatch({
        type: UPDATE_EXPENSE_SUCCESS,
      });
      if (status == 200) {
        dispatch(getExpenseAction());
        dispatch(getExpenseAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been updated",
          timer: 1500,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_EXPENSE_FAIL,
      });
    }
  };
