import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorAction } from "../../../Actions/DoctorAction/DoctorAction";
import SubmitButton from "./SubmitButton";
import Select from "react-select";
import { FaPlus, FaTrash } from "react-icons/fa6";
import Swal from "sweetalert2";
import { getTestAction } from "../../../Actions/LabrotoryAction/TestAction";

const AddTestPatientScreen = ({ patientValue, reset, invoices }) => {
  const dispatch = useDispatch();
  
  const initialState = {
    id:null,
    total: 0,
    discount: null,
    discountPresentage: null,
    finalPrice: null,
    referredBy: "",
  };
  const getTest = useSelector((state) => state.getTest);
  const { tests, loading } = getTest;

  let listTest = [];
  tests?.map((test) => {
    listTest.push({
      label: test.name,
      value: test.id,
    });
  });

  useEffect(() => {
    if (tests?.length == 0) {
      dispatch(getTestAction());
    }
  }, []);
  const [inputValue, setInputValue] = useState(initialState);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };
  const [pirceTemp, setPirceTemp] = useState(null);
  const [pirce, setPirce] = useState([]);
  const [testTemp, settestTemp] = useState(null);
  const [test, setTest] = useState([]);
  const [resultTemp, setResultTemp] = useState(null);
  const [result, setResult] = useState([]);
  const [state, setState] = useState([]);
  const handleAdd = () => {
    if (testTemp == null || test == undefined) {
      return;
    }

    let validate = test.map((item) => {
      if (item.value == testTemp.value) {
        return "dup";
      }
    });

    // Filter out undefined values
    validate = validate.filter((item) => item !== undefined);

    if (validate.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Opps!",
        text: "Failed to add this test , already is added",
      });
      settestTemp(null);

      return;
    }

    let temprice = null;
    tests
      .filter((filter) => filter.id == testTemp.value)
      .map((item) => {
        temprice = item.price;
        setPirceTemp(item.price);
      });
    setPirce([...pirce, temprice]);

    setState([...state, ""]);

    setResult([...result, resultTemp]);
    setTest([...test, testTemp]);
    // setPirce([...pirce, temprice]);
    setResultTemp(null);
    settestTemp(null);
    setPirceTemp(null);
  };

  useEffect(() => {
    if (invoices) {
      
      setInputValue({
        ...inputValue,
        id:invoices.id,
        total: invoices.total,
        discountPresentage: invoices.dicount_money,
        discount: invoices.dicount_percent,
        referredBy: invoices.doctor_id,
      });

      const newPrices =
        invoices?.invoice_test?.map((invoice) => invoice.test.price) || [];
      setPirce((prevPrice) => {
        const priceSet = new Set(prevPrice);
        newPrices.forEach((price) => priceSet.add(price));
        return Array.from(priceSet);
      });
      setTest((prevTest) => {
        // Create a Set of existing values to avoid duplicates
        const existingIds = new Set(prevTest.map((item) => item.value));

        // Filter new invoices to add only those that are not already in the state
        const newTests =
          invoices?.invoice_test
            ?.filter((invoice) => !existingIds.has(invoice.test.id))
            .map((invoice) => ({
              label: invoice.test.name,
              value: invoice.test.id,
            })) || [];

        return [...prevTest, ...newTests];
      });
      setResult((prevResult) => {
        // Create a Set of existing results to avoid duplicates
        const existingResults = new Set(prevResult);

        // Filter new invoices to add only those that are not already in the state
        const newResults =
          invoices?.invoice_test
            ?.filter((invoice) => !existingResults.has(invoice.result))
            .map((invoice) => invoice.result) || [];

        return [...prevResult, ...newResults];
      });
      invoices?.invoice_test?.map((invoice) => {
        setState((prevState) => {
          // Only add if the new state length is less than the invoices length
          if (prevState.length < invoices?.invoice_test?.length) {
            return [...prevState, ""];
          }
          return prevState; // return the same state if the condition is not met
        });
      });
    } else {
      setInputValue(initialState);
      setPirce([]);
      setTest([]);
      setResult([]);
      setState([]);
    }
  }, [invoices]);
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;
  useEffect(() => {
    if (doctors?.length == 0) {
      dispatch(getDoctorAction());
    }
  }, []);
  const handleRemove = (index) => {
    const removeInput = [...state];
    const removeInputResult = [...result];
    const removeInputTest = [...test];
    const removeInputPrice = [...pirce];

    removeInput.splice(index, 1);
    removeInputResult.splice(index, 1);
    removeInputTest.splice(index, 1);
    removeInputPrice.splice(index, 1);

    setState(removeInput);
    setResult(removeInputResult);
    setTest(removeInputTest);
    setPirce(removeInputPrice);
  };
  useEffect(() => {
    if (pirce.length > 0) {
      // Calculate total price using reduce
      const totalPrice = pirce.reduce((accumulator, currentValue) => {
        // Convert currentValue to number using parseFloat
        const currentNumber = parseFloat(currentValue);

        // Add currentNumber to accumulator
        return accumulator + currentNumber;
      }, 0);

      if (inputValue.discount !== null && inputValue.discount !== "") {
        // Apply discount
        const discountAmount = parseFloat(inputValue.discount);

        setInputValue((prevState) => ({
          ...prevState,
          finalPrice: totalPrice - discountAmount,
          total: totalPrice,
          discountPresentage: null, // Reset percentage
        }));
      } else if (
        inputValue.discountPresentage !== null &&
        inputValue.discountPresentage !== ""
      ) {
        // Apply percentage discount
        const discountPresentage = parseFloat(inputValue.discountPresentage);
        setInputValue((prevState) => ({
          ...prevState,
          finalPrice: totalPrice * (1 - discountPresentage / 100),
          total: totalPrice,

          discount: null, // Reset discount
        }));
      } else {
        // No discount or percentage applied
        setInputValue((prevState) => ({
          ...prevState,
          finalPrice: totalPrice,
          total: totalPrice,
        }));
      }
    } else {
      // No pirce, set total to 0
      setInputValue((prevState) => ({
        ...prevState,
        total: 0,

        finalPrice: 0,
      }));
    }
  }, [pirce, inputValue.discount, inputValue.discountPresentage]);
  const handleReset = () => {
    setInputValue(initialState);
    setPirce([]);
    setTest([]);
    setResult([]);
    setState([]);
  };

  return (
    <div className="parent pb-4">
      <div className=" text-gray-100 p-2 bg-indigo-500">Add Test</div>

      <div className="container">
        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Total
          </label>
          <div>
            <input
              type="text"
              className="input w-full"
              name="total"
              readOnly
              disabled
              value={inputValue.total}
              onChange={handleInputChange}
              placeholder="Total"
            />
          </div>

          {/* <div className="text-red-600">{errors && errors.fullname}</div> */}
        </div>

        <div className="parent-input">
          <label htmlFor="" className="lbl">
            discount
          </label>
          <div>
            <input
              type="text"
              className="input w-full"
              name="discount"
              value={inputValue.discount || ""}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              placeholder="discount"
            />
          </div>

          {/* <div className="text-red-600">{errors && errors.fullname}</div> */}
        </div>
        <div className="parent-input">
          <label htmlFor="" className="lbl">
            discount %
          </label>
          <div>
            <input
              type="text"
              className="input w-full"
              name="discountPresentage"
              value={inputValue.discountPresentage || ""}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              placeholder="Discount %"
            />
          </div>
          {/* <div className="text-red-600">{errors && errors.birth_date}</div> */}
        </div>

        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Final Price
          </label>
          <div>
            <input
              type="text"
              className="input w-full"
              name="finalPrice"
              readOnly
              disabled
              value={inputValue.finalPrice || ""}
              // onChange={handleInputChange}
              // onBlur={handleInputChange}
              placeholder="Final Price"
            />
          </div>
          {/* <div className="text-red-600">{errors && errors.birth_date}</div> */}
        </div>

        <div className="parent-input">
          <label htmlFor="referredBy" className="lbl">
            Referred By
          </label>
          <select
            name="referredBy"
            onChange={handleInputChange}
            className="input"
            value={inputValue.referredBy}
            id="referredBy"
          >
            <option value="">Select Doctor</option>
            {doctors?.map((doctor, index) => {
              return (
                <option value={doctor.id} key={index}>
                  {doctor.name}
                </option>
              );
            })}
          </select>
          {/* <div className="text-red-600">{errors && errors.birth_date}</div> */}
        </div>
      </div>
      <hr />
      <div className=" mt-2       m-auto  mb-5 ">
        <div className="grid gap-2 lg:grid-cols-4 m-auto bg-indigo-100 shadow-md p-3 lg:w-11/12 place-items-center">
          <div className="w-full">
            <Select
              options={listTest}
              onChange={(selectOpt) => {
                settestTemp(selectOpt);
                let temprice = null;
                tests
                  .filter((filter) => filter.id == selectOpt.value)
                  .map((item) => {
                    temprice = item.price;
                    setPirceTemp(item.price);
                  });
                // setPirce([...pirce, temprice]);
              }}
              value={testTemp}
            />
          </div>

          <div className="w-full flex gap-2 items-center">
            <input
              type="text"
              placeholder="Result"
              className="input w-full"
              onChange={(e) => {
                setResultTemp(e.target.value);
              }}
              value={resultTemp || ""}
            />
            {/* <div className="">unit</div> */}
          </div>

          <div className="w-full flex gap-2 items-center">
            <input
              type="text"
              placeholder="Price"
              className="input w-full"
              onChange={(e) => setPirceTemp(e.target.value)}
              value={pirceTemp || ""}
            />
            {/* <div className="">unit</div> */}
          </div>

          <div>
            <button onClick={handleAdd} className="btn-info ">
              <FaPlus />
            </button>
          </div>
        </div>
      </div>
      <div className=" ">
        {state.map((value, index) => {
          return (
            <React.Fragment key={index}>
              <div className=" flex  items-center gap-4  bg-white w-11/12 m-auto p-4 rounded-md shadow-md py-3  mb-4">
                <div className="bg-teal-500 text-white px-3 py-1 text-center rounded-full shadow-md ">
                  {index + 1}
                </div>
                <input
                  type="text"
                  value={test[index].label || ""}
                  readOnly
                  disabled
                  className="input w-full mt-1"
                />
                <div className="w-full flex gap-2">
                  <input
                    type="text"
                    value={result[index]}
                    readOnly
                    className="w-full input mt-1"
                    disabled
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    className="input w-full"
                    value={pirce[index]}
                    readOnly
                    disabled
                  />
                </div>

                <div>
                  <button
                    className="bg-red-500 p-2 text-white rounded-sm"
                    onClick={() => {
                      handleRemove(index);
                    }}
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>

      <SubmitButton
        testValue={inputValue}
        result={result}
        patientValue={patientValue}
        test={test}
        price={pirce}
        reset={reset}
        resetTest={handleReset}
        invoice={invoices}
      />
    </div>
  );
};

export default AddTestPatientScreen;
