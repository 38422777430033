import React, { useEffect, useState } from "react";
import { PiTestTubeFill } from "react-icons/pi";
import CreateModals from "../../../Modal/CreateModals";
import CreateTestCategoryModal from "../../../Modal/LabrotoryModal/Test/CreateTestCategoryModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTestCategoryAction,
  getTestCategoryAction,
} from "../../../Actions/LabrotoryAction/TestAction";
import { FaTrash } from "react-icons/fa6";
import DeleteModal from "../../../Modal/DeleteModal";

const TestCategory = () => {
  const [isOpenTestModal, setIsOpenTestModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const getTestCategory = useSelector((state) => state.getTestCategory);
  const { testCategories } = getTestCategory;
  useEffect(() => {
    dispatch(getTestCategoryAction());
  }, []);
  const handleDelete = () => {
    dispatch(deleteTestCategoryAction(id));
    setIsOpenDeleteModal(false)
  };
  return (
    <div className="parent p-2 ">
      <div className="flex gap-2 items-center justify-between">
        <div className="flex gap-2 items-center text-teal-800 font-bold text-lg">
          <div>
            <PiTestTubeFill />
          </div>
          <p>Test Category</p>
        </div>

        <div>
          <button
            onClick={() => setIsOpenTestModal(true)}
            className="btn-info flex gap-2 items-center "
          >
            Add Test <PiTestTubeFill />
          </button>
        </div>
      </div>

      <table className="mt-2">
        <thead className="">
          <tr>
            <th>#</th>
            <th>Test Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {testCategories?.map((test, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{test.name}</td>
                <td>
                  <button
                    onClick={() => {
                      setIsOpenDeleteModal(true);
                      setId(test.id);
                    }}
                    className="btn-danger flex gap-2 items-center "
                  >
                    Delete <FaTrash />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <DeleteModal
        isOpen={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        onDelete={handleDelete}
      ></DeleteModal>
      <CreateModals
        head={"Add Test Category"}
        onClose={() => setIsOpenTestModal(false)}
        isOpen={isOpenTestModal}
      >
        <CreateTestCategoryModal onClose={() => setIsOpenTestModal(false)} />
      </CreateModals>
    </div>
  );
};

export default TestCategory;
