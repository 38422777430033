import React, { useState } from "react";
import ModalPurchasePayback from "../../../Modal/LabrotoryModal/ModalPurchase&Payback";
import CreatePurchaseModal from "../../../Modal/LabrotoryModal/CreatePurchaseModal";
import { FaEdit } from "react-icons/fa";

const PurchasePage = ({ formatNumber, suplier, debt, userId, purchases }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [itemList, setItemList] = useState([]);
  const addExpenseToList = (test) => {
    setItemList((prevList) => {
      if (Array.isArray(prevList)) {
        return [...prevList, test];
      }
      return [test];
    });
  };

  return (
    <div>
      <div className="flex justify-end mb-4">
        <button
          className="px-4 py-2 bg-teal-600 text-white rounded"
          onClick={() => {
            setIsOpenModal(true);
          }}
        >
          Open Modal
        </button>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 border-b">#</th>
            <th className="py-2 border-b">User</th>
            <th className="py-2 border-b">Amount</th>
            <th className="py-2 border-b">Note</th>
            <th className="py-2 border-b">Action</th>
          </tr>
        </thead>
        <tbody>
          {purchases?.map((pay, index) => (
            <tr key={index} className="border-b">
              <td className="py-2">{index + 1}</td>
              <td>{pay.user.name}</td>
              <td className="py-2">{formatNumber(pay.amount)}</td>
              <td className="py-2">{pay.note}</td>
              <td>
                <button
                  onClick={() => {
                    setIsOpenModal(true);

                    addExpenseToList(pay);
                  }}
                  className="btn-primary flex gap-2 items-center"
                >
                  Update <FaEdit />
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td className="py-2 font-bold" colSpan="4">
              Total Debt
            </td>
            <td className="py-2 font-bold text-red-600 ">
              {formatNumber(debt)}
            </td>
          </tr>
        </tbody>
      </table>

      {isOpenModal && (
        <ModalPurchasePayback
          onClose={() => {
            setIsOpenModal(false);
            setItemList([]);
          }}
          head={itemList.length > 0 ? " Update Purchase" : "Add Purchase"}
        >
          <CreatePurchaseModal
            userId={userId}
            suplier={suplier}
            onClose={() => {
              setIsOpenModal(false);
              setItemList([]);
            }}
            itemList={itemList}
          />
        </ModalPurchasePayback>
      )}
    </div>
  );
};

export default PurchasePage;
