import React, { useEffect, useState } from "react";
import { GrTest } from "react-icons/gr";
import CreateModals from "../../../Modal/CreateModals";
import CreateMangeTestsModal from "../../../Modal/LabrotoryModal/Test/CreateMangeTestsModal";
import {
  getMangeTestAction,
  getTestCategoryAction,
} from "../../../Actions/LabrotoryAction/TestAction";
import { useDispatch, useSelector } from "react-redux";
import { CiEdit } from "react-icons/ci";

const MangeTest = () => {
  const dispatch = useDispatch();
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const getTestCategory = useSelector((state) => state.getTestCategory);
  const { testCategories } = getTestCategory;
  const getMangeTests = useSelector((state) => state.getMangeTests);
  const { mangeTests } = getMangeTests;
  const [mangeTestLisit, setMangeTestList] = useState([]);
  const addTestToMangeTestList = (test) => {
    setMangeTestList((prevList) => {
      if (Array.isArray(prevList)) {
        return [...prevList, test];
      }
      return [test];
    });
  };
  useEffect(() => {
    dispatch(getTestCategoryAction());
    dispatch(getMangeTestAction());
  }, []);

  return (
    <div className="parent p-2 ">
      <div className="flex gap-2 items-center justify-between">
        <div className="flex gap-2 items-center text-blue-800 font-bold text-lg">
          <div>
            <GrTest />
          </div>
          <p>Mange Tests</p>
        </div>

        <div>
          <button
            onClick={() => setIsOpenCreateModal(true)}
            className="btn-primary flex gap-2 items-center "
          >
            Add Mange Test <GrTest />
          </button>
        </div>
      </div>

      <table className="mt-2">
        <thead className="">
          <tr>
            <th>#</th>
            <th>Test Name</th>
            <th>cost</th>
            <th>price</th>
            <th>unit</th>
            <th>category</th>
            <th>instrument</th>
            <th>normal_range</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {mangeTests?.map((test, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{test.name}</td>
                <td>{test.cost}</td>
                <td>{test.price}</td>
                <td>{test.unit}</td>
                <td>{test.category.name}</td>

                <td>{test.instrument}</td>
                <td>{test.normal_range}</td>
                <td>
                  <div
                    onClick={() => {
                      setIsOpenCreateModal(true);
                      addTestToMangeTestList(test);
                    }}
                    className="flex gap-2 items-center"
                  >
                    <button className="btn-info flex gap-2 items-center  ">
                      update <CiEdit />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <CreateModals
        isOpen={isOpenCreateModal}
        onClose={() => {
          setIsOpenCreateModal(false);
          setMangeTestList([]);
        }}
        head={
          mangeTestLisit.length > 0 ? "Update Mange Tests" : "Add Mange Tests"
        }
      >
        <CreateMangeTestsModal
          onClose={() => {
            setIsOpenCreateModal(false);
            setMangeTestList([]);
          }}
          testCategories={testCategories}
          mangeTestLisit={mangeTestLisit}
        />
      </CreateModals>
    </div>
  );
};

export default MangeTest;
