import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLabReportAction,
  getLabReportByDoctorAction,
  getLabReportByProfitAction,
  getLabReportByTestAction,
} from "../../../Actions/LabrotoryAction/LabReportAction";
import Select from "react-select";
import { getDoctorAction } from "../../../Actions/DoctorAction/DoctorAction";
import { getTestAction } from "../../../Actions/LabrotoryAction/TestAction";
import ReprotByDoctor from "./ReprotByDoctor";
import ReportByTest from "./ReportByTest";
import LabReport from "./LabReport";
import ProfitReportPage from "./ProfitReportPage";
const ReportPage = () => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useState({
    from: "",
    to: "",
    doctor_id: "",
    test_id: "",
  });
  const [type, setType] = useState(null);
  const handleInputChange = (e) => {
    setSearchParam({
      ...searchParam,
      [e.target.name]: e.target.value,
    });
  };
  const { from, to, doctor_id, test_id } = searchParam;
  useEffect(() => {
    dispatch(getDoctorAction());
    dispatch(getTestAction());
  }, []);
  const getTest = useSelector((state) => state.getTest);
  const { tests } = getTest;
  const getLabReportByTest = useSelector((state) => state.getLabReportByTest);
  const { byTest } = getLabReportByTest;
  const getLabReport = useSelector((state) => state.getLabReport);
  const { reports } = getLabReport;

  const profits = useSelector((state) => state.getLabReportProfit.profits);

  const handleSearch = (e, type) => {
    e.preventDefault();
    setType(type);
    if (type == "dr") {
      if (doctor_id.value) {
        dispatch(getLabReportByDoctorAction(from, to, doctor_id.value));
      }
    }
    if (type == "test") {
      if (test_id.value) {
        dispatch(getLabReportByTestAction(from, to, test_id.value));
      }
    }
    if (type == "report") {
      dispatch(getLabReportAction(from, to));
    }
    if (type == "profit") {
      dispatch(getLabReportByProfitAction(from, to));
    }
  };
  const { doctors } = useSelector((state) => state.getDoctor);
  let doctorList = [];
  doctors?.map((doctor) => {
    doctorList.push({
      label: doctor.name,
      value: doctor.id,
    });
  });

  let testList = [];
  tests?.map((doctor) => {
    testList.push({
      label: doctor.name,
      value: doctor.id,
    });
  });
  const getLabReportByDoctor = useSelector(
    (state) => state.getLabReportByDoctor
  );
  const { byDoctor } = getLabReportByDoctor;
  console.log(byTest);
  return (
    <div className="mx-auto p-4 w-full">
      <div className="flex gap-2 justify-between">
        <h2 className="text-2xl font-bold mb-6">Report Details</h2>
      </div>

      <div className="bg-white shadow w-full rounded-lg p-4">
        <form action="">
          <div className="flex gap-2 items-center">
            <input
              type="date"
              onChange={handleInputChange}
              name="from"
              className="input w-full"
              id=""
            />
            TO
            <input
              type="date"
              name="to"
              onChange={handleInputChange}
              className="input w-full"
            />
          </div>
          <div className="flex gap-2">
            <div className="flex flex-col w-full">
              <label htmlFor="doctor">Doctor</label>
              <Select
                id="doctor"
                options={doctorList}
                onChange={(selected) => {
                  setSearchParam({
                    ...searchParam,
                    doctor_id: selected,
                  });
                }}
                value={doctor_id}
                isClearable
              />
            </div>

            <div className="flex flex-col w-full">
              <label htmlFor="test">Test</label>
              <Select
                id="test"
                options={testList}
                onChange={(selected) => {
                  setSearchParam({
                    ...searchParam,
                    test_id: selected,
                  });
                }}
                value={test_id}
                isClearable
              />
            </div>
          </div>

          <div className="flex gap-2 items-center justify-center mt-5">
            <button className="btn-info" onClick={(e) => handleSearch(e, "dr")}>
              Get Report By Doctor{" "}
            </button>
            <button
              className="btn-primary"
              onClick={(e) => handleSearch(e, "test")}
            >
              Get Report By Test{" "}
            </button>
            <button
              className="btn-warning"
              onClick={(e) => handleSearch(e, "report")}
            >
              Get Report{" "}
            </button>
            <button
              className="btn-danger"
              onClick={(e) => handleSearch(e, "profit")}
            >
              Get Report Benfit{" "}
            </button>
          </div>
        </form>
        {/* Report By docotr */}
        {type == "dr" && <ReprotByDoctor byDoctor={byDoctor} />}
        {type == "test" && <ReportByTest byTest={byTest} />}
        {type == "report" && <LabReport reports={reports} />}
        {type == "profit" && <ProfitReportPage profits={profits} />}
      </div>
    </div>
  );
};

export default ReportPage;
