import React, { useEffect } from "react";
import Side from "./Components/Pages/Bars/Side";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./Components/Pages/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import Doctor from "./Components/Pages/DoctorScreen/Doctor";
import Occupation from "./Components/Pages/Ocuupation/Occupation";
import Drug from "./Components/Pages/Drug/Drug";
import PatientScreen from "./Components/Pages/PatientScreen/PatientScreen";
import TodayPatient from "./Components/Pages/PatientScreen/TodayPatient";
import ProfileVisits from "./Components/Pages/PatientScreen/Visits/ProfileVisits";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";
import User from "./Components/Pages/Users/User";
import ShowUser from "./Components/Pages/Users/ShowUser";
import UpdateUser from "./Components/Pages/Users/UpdateUser";
import Search from "./Components/Pages/PatientScreen/Search/Search";
import { getUserAuthAction } from "./Components/Actions/UserAction/UserAction";
import PatientLabScreen from "./Components/Pages/LabrotoryPages/PatientLab/PatientLabScreen";
import ListInvoices from "./Components/Pages/LabrotoryPages/PatientLab/ListInvoices";
import EnterResults from "./Components/Pages/LabrotoryPages/PatientLab/EnterResults";
import EditInvoiceScreen from "./Components/Pages/LabrotoryPages/PatientLab/EditInvoiceScreen";
import TestCategory from "./Components/Pages/LabrotoryPages/Test/TestCategory";
import MangeTest from "./Components/Pages/LabrotoryPages/Test/MangeTest";
import ExpenseScreen from "./Components/Pages/LabrotoryPages/ExpensePage/ExpenseScreen";
import SuplierPage from "./Components/Pages/LabrotoryPages/SuplierPage/SuplierPage";
import EachSuplier from "./Components/Pages/LabrotoryPages/SuplierPage/EachSuplier";
import ReportPage from "./Components/Pages/LabrotoryPages/SuplierPage/ReportPage";
const App = () => {
  const loginReducer = useSelector((state) => state.loginReducer);
  const { loading, userInfo, isLoggedIn } = loginReducer;
  const token = localStorage.getItem("token");
  const getUsersAuth = useSelector((state) => state.getUsersAuth);
  const { user } = getUsersAuth;
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(getUserAuthAction());
    }
  }, [token]);
  useEffect(() => {
    if (userInfo) {
      return;
    } else {
      localStorage.removeItem("token");
    }
  }, [userInfo]);
  return (
    <div>
      <BrowserRouter>
        {userInfo && token ? (
          <React.Fragment>
            <div className="flex">
              <div className="">
                <Side user={user} />
              </div>
              <div className="h-screen overflow-y-auto w-full overflow-x-hidden">
                <Routes>
                  {/* Labrotory */}
                  <Route path="/" element={<PatientLabScreen />} />
                  <Route path="/invoice" element={<ListInvoices />} />
                  <Route path="/enterResult" element={<EnterResults />} />
                  <Route path="/editInvoice" element={<EditInvoiceScreen />} />
                  <Route path="/testCategory" element={<TestCategory />} />
                  <Route path="/mangeTest" element={<MangeTest />} />
                  <Route
                    path="/expense"
                    element={<ExpenseScreen user={user} />}
                  />
                  {user?.admin_type == 1 && (
                    <Route
                      path="/suplier"
                      element={<SuplierPage user={user} />}
                    />
                  )}
                  {user?.admin_type == 1 && (
                    <Route
                      path="/eachSuplier"
                      element={<EachSuplier user={user} />}
                    />
                  )}
                         {user?.admin_type == 1 && (
                    <Route
                      path="/report"
                      element={<ReportPage user={user} />}
                    />
                  )}

                  {/* Clinic */}
                  {/* <Route path="/search" element={<Search />} /> */}
                  {user?.admin_type == 1 && (
                    <Route path="/user" element={<ShowUser />} />
                  )}
                  {user?.admin_type == 1 && (
                    <Route path="/user/show/:id" element={<UpdateUser />} />
                  )}
                  {user?.admin_type == 1 && (
                    <Route path="/user/create" element={<User />} />
                  )}
                  {/* <Route path="/" element={<Dashboard />} /> */}
                  {/* <Route path="/doctor" element={<Doctor />} /> */}
                  {/* <Route path="/occupation" element={<Occupation />} /> */}
                  {/* <Route path="/drug" element={<Drug />} /> */}
                  {/* <Route path="/patient" element={<PatientScreen />} /> */}
                  {/* <Route path="/todayPatient" element={<TodayPatient />} /> */}
                  {/* <Route
                    path="/profile/visits/:id"
                    element={<ProfileVisits />}
                  /> */}
                </Routes>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Login />
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
