import React, { useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { FaListUl, FaUser, FaUserDoctor, FaUserInjured } from "react-icons/fa6";
import { AiOutlineHeart } from "react-icons/ai";

import { MdOutlineSensorOccupied } from "react-icons/md";
import { Link } from "react-router-dom";
import { CiPillsBottle1 } from "react-icons/ci";
import { IoSearch } from "react-icons/io5";
import { IoIosLogOut } from "react-icons/io";
import { useDispatch } from "react-redux";
import { logOutAction } from "../../Actions/login";
import { PiScanThin, PiTestTubeFill } from "react-icons/pi";
import { GrTest } from "react-icons/gr";
import { LuBadgeDollarSign } from "react-icons/lu";
import { BiSolidReport } from "react-icons/bi";

const Side = ({ user }) => {
  const menus = [
    // { name: "dashboard", link: "/", icon: MdOutlineDashboard },
    { name: "Patient", link: "/", icon: FaUserInjured },
    { name: "List Patient", link: "/invoice", icon: FaListUl },
    { name: "Test Categories", link: "/testCategory", icon: PiTestTubeFill },
    { name: "Mange Test", link: "/mangeTest", icon: GrTest },
    
    { name: "Expense", link: "/expense", icon: LuBadgeDollarSign },
    { name: "Suplier", link: "/suplier", icon: PiScanThin },
    { name: "Report", link: "/report", icon: BiSolidReport },

    // { name: "Patient", link: "/patient", icon: FaUserInjured },
    // { name: "Today Patient", link: "/todayPatient", icon: FaListUl },
    // { name: "Doctor", link: "/doctor", icon: FaUserDoctor, margin: true },
    // { name: "Occupation", link: "/occupation", icon: MdOutlineSensorOccupied },
    // { name: "Drug", link: "/drug", icon: CiPillsBottle1 },
    // { name: "Search", link: "/search", icon: IoSearch },
  ];

  // Check if user is an admin and add the "Users" menu item accordingly
  if (user && user.admin_type === 1) {
    menus.push({ name: "users", link: "/user", icon: FaUser });
  }
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logOutAction());
  };
  const [open, setOpen] = useState(true);
  return (
    <section className="flex gap-6">
      <div
        className={`bg-[#0e0e0e] min-h-screen ${
          open ? "w-72" : "w-16"
        } duration-500 text-gray-100 px-4`}
      >
        <div className="py-3 items-center flex justify-between mt-4">
          <div className="">
            <p className={open ? "block" : "hidden"}>{user?.name}</p>
          </div>
          <HiMenuAlt3
            size={26}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
          />
        </div>
        <hr />
        <div className="mt-4 flex flex-col gap-4 relative">
          {menus?.map((menu, i) => (
            <Link
              to={menu?.link}
              key={i}
              className={` ${
                menu?.margin && "mt-5"
              } group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`}
            >
              <div>{React.createElement(menu?.icon, { size: "20" })}</div>
              <h2
                style={{
                  transitionDelay: `${i + 3}00ms`,
                }}
                className={`whitespace-pre duration-500 ${
                  !open && "opacity-0 translate-x-28 overflow-hidden"
                }`}
              >
                {menu?.name}
              </h2>
              <h2
                className={`${
                  open && "hidden"
                } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
              >
                {menu?.name}
              </h2>
            </Link>
          ))}

          <Link
            onClick={handleLogout}
            className={` mt-5 group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`}
          >
            <div className="text-xl">
              <IoIosLogOut />
            </div>
            <h2
              style={{
                transitionDelay: `${7 + 3}00ms`,
              }}
              className={`whitespace-pre duration-500 ${
                !open && "opacity-0 translate-x-28 overflow-hidden"
              }`}
            >
              Log Out
            </h2>
            <h2
              className={`${
                open && "hidden"
              } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
            >
              Log Out
            </h2>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Side;
