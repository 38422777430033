import Swal from "sweetalert2";

import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_PURCHASE_FAIL,
  CREATE_PURCHASE_REQUEST,
  CREATE_PURCHASE_SUCCESS,
  SHOW_PURCHASE_FAIL,
  SHOW_PURCHASE_REQUEST,
  SHOW_PURCHASE_SUCCESS,
  UPDATE_PURCHASE_FAIL,
  UPDATE_PURCHASE_REQUEST,
  UPDATE_PURCHASE_SUCCESS,
} from "../../ActionType/LabrotoryTypes/PurchaseTypes";

export const createPurchaseAction =
  (suplier_id, user_id, amount, note) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_PURCHASE_REQUEST,
    });
    try {
      const resp = await api.post(
        "purchase/create",
        { suplier_id, user_id, amount, note },
        config
      );
      const { status, data } = resp;

      dispatch({
        type: CREATE_PURCHASE_SUCCESS,
        payload: data,
      });
      if (status == 201) {
        dispatch(showPurchaseAction(suplier_id));
        dispatch(showPurchaseAction(suplier_id));
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_PURCHASE_FAIL,
      });
    }
  };

export const showPurchaseAction = (suplier_id) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: SHOW_PURCHASE_REQUEST,
  });
  try {
    const resp = await api.get("purchase/show/" + suplier_id, config);
    const { status, data } = resp;

    if (status == 200) {
      dispatch({
        type: SHOW_PURCHASE_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: SHOW_PURCHASE_FAIL,
    });
  }
};

export const updatePurchaseAction =
  (id, suplier_id, user_id, amount, note) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: UPDATE_PURCHASE_REQUEST,
    });
    try {
      const resp = await api.put(
        "purchase/update/" + id,
        { user_id, amount, note, suplier_id },
        config
      );
      const { status, data } = resp;
      dispatch({
        type: UPDATE_PURCHASE_SUCCESS,
      });
      if (status == 200) {
        dispatch(showPurchaseAction(suplier_id));
        dispatch(showPurchaseAction(suplier_id));
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been updated",
          timer: 1500,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PURCHASE_FAIL,
      });
    }
  };
