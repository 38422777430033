import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import Modal from "../../../Modal/Modal";
import ViewPatientInfo from "../../../Modal/LabrotoryModal/ViewPatientInfo";
import { MdOutlineSaveAlt } from "react-icons/md";
import { useDispatch } from "react-redux";
import { updateInvoiceResultTestAction } from "../../../Actions/LabrotoryAction/InvoiceAction";

const EnterResults = () => {
  const location = useLocation();
  const { invoices } = location.state || {};
  const [patientInfo, setPatientInfo] = useState();
  const dispatch = useDispatch();
  const [isOpenViewPatientInfoModall, setIsOpenViewPatientInfoModal] =
    useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    let temp = [];
    invoices?.invoice_test?.map((item) => {
      temp.push(item.result);
      setResults(temp);
    });
  }, []);
  const handleSubmit = () => {
    let ids = [];
    invoices?.invoice_test?.map((item) => {
      ids.push(item.id);
    });
    dispatch(updateInvoiceResultTestAction(ids, results));
  };
  return (
    <>
      <div className="m-auto w-11/12 mt-3 bg-gray-100 shadow-md rounded-sm">
        <div className=" bg-[#17A2B8] text-white justify-items-center place-items-center grid grid-cols-3 gap-2 w-full text p-1 rounded-sm shadow-sm">
          <div>{invoices.patient.fullname}</div>

          <p className="text-lg">Dr : {invoices?.dcotor?.name}</p>
          <div>
            <button
              onClick={() => {
                setIsOpenViewPatientInfoModal(true);

                setPatientInfo(invoices);
              }}
              className="bg-yellow-500 hover:bg-yellow-600 duration-200 px-4 py-1 rounded-sm shadow-md flex gap-2 items-center"
            >
              View <FaEye />
            </button>
          </div>
        </div>
        <table className="">
          <thead className="">
            <tr>
              <th>#</th>
              <th>Test Name</th>
              <th>Result</th>
              <th>Unit</th>
              <th>Normal Range</th>
            </tr>
          </thead>
          {invoices?.invoice_test?.map((test, index) => {
            return (
              <tbody>
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{test.test.name}</td>
                  <td>
                    <input
                      type="text"
                      value={results[index]}
                      onChange={(e) => {
                        let tempValue = [...results];
                        tempValue[index] = e.target.value;
                        setResults(tempValue);
                      }}
                      className="input"
                    />
                  </td>
                  <td>{test.test.unit}</td>
                  <td>{test.test.normal_range}</td>
                </tr>
              </tbody>
            );
          })}{" "}
        </table>
        <div className="flex gap-4 justify-center py-3 mt-4">
          <button
            onClick={handleSubmit}
            className="bg-green-500  justify-center lg:w-7/12 hover:bg-green-600 duration-300 px-2 py-0.5 text-white rounded-sm shadow-md p-0 flex gap-2 items-center"
          >
            Save
            <MdOutlineSaveAlt />
          </button>
        </div>
      </div>

      {isOpenViewPatientInfoModall && (
        <Modal
          isOpen={isOpenViewPatientInfoModall}
          head={"Patient Information"}
          onClose={() => setIsOpenViewPatientInfoModal(false)}
        >
          <ViewPatientInfo patient={patientInfo} />
        </Modal>
      )}

      <div className="pb-10"></div>
    </>
  );
};

export default EnterResults;
