import {
  FETCH_INVOICE_FAIL,
  FETCH_INVOICE_REQUEST,
  FETCH_INVOICE_SUCCESS,
  UPDATE_INVOICE_TEST_FAIL,
  UPDATE_INVOICE_TEST_REQUEST,
  UPDATE_INVOICE_TEST_SUCCESS,
} from "../../ActionType/LabrotoryTypes/InvoiceTypes";

export const getInvoiceReducer = (
  state = { loading: true, invoices: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_INVOICE_REQUEST:
      return {
        loading: true,
      };
    case FETCH_INVOICE_SUCCESS:
      return {
        loading: false,
        invoices: action.payload.data,
        total: action.payload.total,

        error: "",
      };
    case FETCH_INVOICE_FAIL:
      return {
        loading: false,
        invoices: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};



export const updateInvoiceTestReducer = (
  state = { loading: true, updateInvoiceTests: [],  error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_INVOICE_TEST_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_INVOICE_TEST_SUCCESS:
      return {
        loading: false,
        updateInvoiceTests: action.payload,
        error: "",
      };
    case UPDATE_INVOICE_TEST_FAIL:
      return {
        loading: false,
        updateInvoiceTests: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};
