import React, { useEffect, useState } from "react";
import { MdOutlineSaveAlt } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import {
  createPaybackAction,
  updatePaybackAction,
} from "../../Actions/LabrotoryAction/PaybackAction";
import { getSuplierAction } from "../../Actions/LabrotoryAction/SuplierAction";

const CreatePaybackModal = ({ userId, suplier, onClose, itemList }) => {
  const [inputValue, setInputValue] = useState({
    id: "",
    note: "",
    amount: "",
  });
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (itemList?.length > 0) {
      itemList?.map((item) => {
        setInputValue({
          ...inputValue,
          id: item.id,
          amount: item.amount,
          note: item.note,
        });
      });
    }
  }, [itemList]);

  const validate = () => {
    const errors = {};

    if (!inputValue.amount) {
      errors.amount = "Amount is required";
    } else if (isNaN(inputValue.amount) || parseFloat(inputValue.amount) <= 0) {
      errors.amount = "Amount must be a positive number";
    }

    if (!inputValue.note) {
      errors.note = "Note is required";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();

    if (Object.keys(errors).length === 0) {
      if (inputValue.id) {
        dispatch(
          updatePaybackAction(
            inputValue.id,
            suplier.id,
            userId,
            inputValue.amount,
            inputValue.note
          )
        );
        // Clear the form
        setInputValue({
          amount: "",
          note: "",
          id: "",
        });
        onClose();
        setErrors({});
      } else {
        dispatch(
          createPaybackAction(
            suplier.id,
            userId,
            inputValue.amount,
            inputValue.note
          )
        );

        // Clear the form
        setInputValue({
          amount: "",
          note: "",
          id: "",
        });
        onClose();
        setErrors({});
      }
    } else {
      setErrors(errors);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <div>
            <label htmlFor="amount">Amount </label>
          </div>
          <input
            type="text"
            id="amount"
            className="input w-full"
            name="amount"
            value={inputValue.amount}
            onChange={handleChange}
          />
          {errors.amount && (
            <span className="text-red-500">{errors.amount}</span>
          )}
        </div>

        <div className="flex flex-col">
          <div>
            <label htmlFor="reason">Note </label>
          </div>
          <textarea
            id="reason"
            className="input w-full"
            name="note"
            value={inputValue.note}
            onChange={handleChange}
          ></textarea>
          {errors.note && <span className="text-red-500">{errors.note}</span>}
        </div>

        <button
          type="submit"
          className="btn-primary flex gap-2 items-center mt-3"
        >
          Save <MdOutlineSaveAlt />
        </button>
      </form>
    </div>
  );
};

export default CreatePaybackModal;
