import Swal from "sweetalert2";

import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_PATIENT_FAIL,
  CREATE_PATIENT_REQUEST,
  CREATE_PATIENT_SUCCESS,
  DELETE_PATIENT_FAIL,
  DELETE_PATIENT_REQUEST,
  DELETE_PATIENT_SUCCESS,
} from "../../ActionType/PatientTypes/PatientTypes";
import {
  createInvoiceAction,
  createInvoiceTestAction,
  getInvoiceAction,
  updateInvoiceAction,
} from "./InvoiceAction";

export const createPatientLabrotoryAction =
  (
    fullname,
    phone,
    birth_date,
    gender,
    test,
    result,
    price,
    testIds,
    reset,
    resetTest
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_PATIENT_REQUEST,
    });
    try {
      const resp = await api.post(
        "patient/create",
        {
          fullname,
          phone,
          birth_date,
          gender,
          doctor_id: "",
          occupation_id: "",
          marital_status: "",
          address: "",
          blood: "",
        },
        config
      );
      const { status, data } = resp;
      dispatch({
        type: CREATE_PATIENT_SUCCESS,
      });
      if (status == 201) {
        dispatch(
          createInvoiceAction(
            data.id,
            test.referredBy,
            test.total,
            test.discount,
            test.discountPresentage,
            result,
            testIds,
            resetTest
          )
        );
        reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",

          timer: 1500,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_PATIENT_FAIL,
        error: error.response.data.errors,
      });
    }
  };

export const updatePatientLabrotoryAction =
  (
    id,
    fullname,
    phone,
    birth_date,
    gender,
    test,
    result,
    price,
    testIds,
    reset,
    resetTest,
    invoiceTestIds
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_PATIENT_REQUEST,
    });
    try {
      const resp = await api.put(
        "patient/update/" + id,
        {
          fullname,
          phone,
          birth_date,
          gender,
          doctor_id: "",
          occupation_id: "",
          marital_status: "",
          address: "",
          blood: "",
        },
        config
      );
      const { status, data } = resp;
      dispatch({
        type: CREATE_PATIENT_SUCCESS,
      });
      if (status == 201) {
        dispatch(
          updateInvoiceAction(
            test.id,
            id, //patient id
            test.referredBy,
            test.total,
            test.discount,
            test.discountPresentage,
            result,
            testIds,
            resetTest,
            invoiceTestIds
          )
        );
        // reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been updated",

          timer: 1500,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_PATIENT_FAIL,
        error: error.response.data.errors,
      });
    }
  };

export const deletePatientLabrotoryAction = (id) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: DELETE_PATIENT_REQUEST,
  });
  try {
    const resp = await api.delete("patient/delete/" + id, config);
    const { status, data } = resp;
    dispatch({
      type: DELETE_PATIENT_SUCCESS,
    });
    if (status == 200) {
      dispatch(getInvoiceAction());
      dispatch(getInvoiceAction());

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Delete Was Successfully",

        timer: 1500,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_PATIENT_FAIL,
      error: error.response.data.errors,
    });
  }
};
