import React, { useEffect, useRef, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { FaEye, FaFileInvoice, FaPrint, FaTrash } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceAction } from "../../../Actions/LabrotoryAction/InvoiceAction";
import Modal from "../../../Modal/Modal";
import ViewPatientInfo from "../../../Modal/LabrotoryModal/ViewPatientInfo";
import { FaEdit } from "react-icons/fa";
import { IoEnter } from "react-icons/io5";
import { deletePatientLabrotoryAction } from "../../../Actions/LabrotoryAction/PatientAction";
import { useNavigate } from "react-router-dom";
import FilterModal from "../../../Modal/LabrotoryModal/FilterModal";
import { getMangeTestAction } from "../../../Actions/LabrotoryAction/TestAction";
import { getDoctorAction } from "../../../Actions/DoctorAction/DoctorAction";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack } from "react-icons/io";
import { useReactToPrint } from "react-to-print";
import PrintPageLab from "../PrintPageLab/PrintPageLab";

const ListInvoices = () => {
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    patient_name: null,
    patient_code: null,
    from: null,
    to: null,
    testId: null,
    doctorId: null,
  });
  const navigate = useNavigate();
  const getInvoice = useSelector((state) => state.getInvoice);
  const { invoices, total } = getInvoice;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getInvoiceAction(
        page,
        perPage,
        formData.patient_name,
        formData.patient_code,
        formData.from,
        formData.to,
        formData.testId,
        formData.doctorId
      )
    );
  }, [perPage]);
  const [patientInfo, setPatientInfo] = useState();
  const [isOpenViewPatientInfoModall, setIsOpenViewPatientInfoModal] =
    useState(false);
  const [isOpenModalFilter, setIsOpenFilterModal] = useState(false);
  useEffect(() => {
    dispatch(getMangeTestAction());
    dispatch(getDoctorAction());
  }, []);
  const getMangeTests = useSelector((state) => state.getMangeTests);
  const { mangeTests } = getMangeTests;

  const getDoctor = useSelector((state) => state.getDoctor);
  const [invoiceList, setInvoiceList] = useState([]);
  const { doctors, loading } = getDoctor;
  const onPageChange = (event) => {
    setPage(event.selected);
    dispatch(
      getInvoiceAction(
        event.selected + 1,
        perPage,
        formData.patient_name,
        formData.patient_code,
        formData.from,
        formData.to,
        formData.testId,
        formData.doctorId
      )
    );
  };

  const printRef = useRef();
  useEffect(() => {
    if (Object.keys(invoiceList).length > 0) {
      handlePrint();
      setInvoiceList({}); // Clear invoiceList after printing
    }
  }, [invoiceList]);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Test Report",
  });
  return (
    <>
      <div className=" lg:w-[95%]  mt-2  m-auto rounded-sm p-2">
        <div className="flex items-center justify-between bg-gray-100 p-3">
          <div className="flex gap-2 items-center">
            <p className="text-blue-700 text-xl">
              <FaFileInvoice />
            </p>
            <p className="font-bold">List Invoices</p>
          </div>
          <div>
            <button
              onClick={() => setIsOpenFilterModal(true)}
              className="btn-primary flex gap-2 items-center "
            >
              Filter <CiFilter />
            </button>
          </div>
        </div>
        {invoices?.map((invoice, index) => {
          return (
            <div key={index} className="bg-gray-50  rounded-sm  mt-5 shadow-lg">
              <div className=" bg-[#17A2B8] text-white justify-items-center place-items-center grid grid-cols-3 gap-2 w-full text p-1 rounded-sm shadow-sm">
                <div>{invoice?.patient?.fullname}</div>

                <p className="text-lg">Dr : {invoice?.dcotor?.name}</p>
                <div>
                  <button
                    onClick={() => {
                      setIsOpenViewPatientInfoModal(true);

                      setPatientInfo(invoice);
                    }}
                    className="bg-yellow-500 hover:bg-yellow-600 duration-200 px-4 py-1 rounded-sm shadow-md flex gap-2 items-center"
                  >
                    View <FaEye />
                  </button>
                </div>
              </div>

              <table className="">
                <thead className="">
                  <tr>
                    <th>#</th>
                    <th>Test Name</th>
                    <th>Result</th>
                    <th>Unit</th>
                    <th>Normal Range</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice?.invoice_test?.map((test, tIndex) => {
                    return (
                      <tr key={tIndex}>
                        <td>{tIndex + 1}</td>
                        <td>{test.test.name}</td>
                        <td>{test.result}</td>
                        <td>{test.test.unit}</td>
                        <td>{test.test.normal_range}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="flex gap-4 justify-center py-3 mt-4">
                <button
                  onClick={() =>
                    navigate("/editInvoice", { state: { invoices: invoice } })
                  }
                  className="bg-green-500 hover:bg-green-600 duration-300 px-2 py-0.5 text-white rounded-sm shadow-md p-0 flex gap-2 items-center"
                >
                  Edit
                  <FaEdit />
                </button>
                <button
                  onClick={() => {
                    // handlePrint();
                    setInvoiceList(invoice);
                  }}
                  className="bg-indigo-500 hover:bg-indigo-600 duration-300 px-2 py-0.5 text-white rounded-sm shadow-md flex gap-2 items-center"
                >
                  Print
                  <FaPrint />
                </button>
                <button
                  onClick={() =>
                    navigate("/enterResult", { state: { invoices: invoice } })
                  }
                  className="bg-yellow-500 hover:bg-yellow-600 duration-300 px-2 py-0.5 text-white rounded-sm shadow-md flex gap-2 items-center"
                >
                  Enter Result
                  <IoEnter />
                </button>
                <button
                  onClick={() => {
                    dispatch(deletePatientLabrotoryAction(invoice.patient.id));
                  }}
                  className="bg-red-500 hover:bg-red-600 duration-300 px-2 py-0.5 text-white rounded-sm shadow-md flex gap-2 items-center"
                >
                  Delete <FaTrash />
                </button>
              </div>
            </div>
          );
        })}
        <div className="hidden">
          <PrintPageLab ref={printRef} invoices={invoiceList} />
        </div>
        <div className="flex gap-3 items-center justify-end mt-2">
          <div className="mt-2 md:w-1/12">
            <select
              className="outline-none border-blue-400 rounded-sm border py-1 w-full"
              value={perPage}
              onChange={(e) => setPerPage(e.target.value)}
            >
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(
                (perPage, index) => {
                  return (
                    <option value={perPage} key={index}>
                      {perPage}
                    </option>
                  );
                }
              )}
            </select>
          </div>
          <ReactPaginate
            breakLabel={<span className="break">...</span>}
            nextLabel={
              <p className="  arrow rotate-180">
                <IoIosArrowBack />
              </p>
            }
            onPageChange={onPageChange}
            forcePage={page}
            pageRangeDisplayed={5}
            pageCount={total / perPage}
            previousLabel={
              <p className=" arrow">
                <IoIosArrowBack />
              </p>
            }
            renderOnZeroPageCount={null}
            containerClassName="paginate-container"
            activeClassName="active"
          />
        </div>
      </div>
      {isOpenViewPatientInfoModall && (
        <Modal
          isOpen={isOpenViewPatientInfoModall}
          head={"Patient Information"}
          onClose={() => setIsOpenViewPatientInfoModal(false)}
        >
          <ViewPatientInfo patient={patientInfo} />
        </Modal>
      )}

      {isOpenModalFilter && (
        <FilterModal
          isOpen={isOpenModalFilter}
          formData={formData}
          setFormData={setFormData}
          handleSearch={() =>
            dispatch(
              getInvoiceAction(
                page,
                perPage,
                formData.patient_name,
                formData.patient_code,
                formData.from,
                formData.to,
                formData.testId,
                formData.doctorId
              )
            )
          }
          onClose={() => setIsOpenFilterModal(false)}
          mangeTests={mangeTests}
          doctors={doctors}
        />
      )}

      <div className="pb-10"></div>
    </>
  );
};

export default ListInvoices;
