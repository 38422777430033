import Swal from "sweetalert2";

import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_SUPLIER_FAIL,
  CREATE_SUPLIER_REQUEST,
  CREATE_SUPLIER_SUCCESS,
  FETCH_SUPLIER_FAIL,
  FETCH_SUPLIER_REQUEST,
  FETCH_SUPLIER_SUCCESS,
  UPDATE_SUPLIER_FAIL,
  UPDATE_SUPLIER_REQUEST,
  UPDATE_SUPLIER_SUCCESS,
} from "../../ActionType/LabrotoryTypes/SuplierTupe";

export const getSuplierAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: FETCH_SUPLIER_REQUEST,
  });
  try {
    const resp = await api.get("suplier", config);
    const { status, data } = resp;

    if (status == 200) {
      dispatch({
        type: FETCH_SUPLIER_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_SUPLIER_FAIL,
    });
  }
};

export const createSuplierAction =
  (name, phone, address) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_SUPLIER_REQUEST,
    });
    try {
      const resp = await api.post(
        "suplier/create",
        { name, phone, address },
        config
      );
      const { status, data } = resp;
      dispatch({
        type: CREATE_SUPLIER_SUCCESS,
      });
      if (status == 201) {
        dispatch(getSuplierAction());
        dispatch(getSuplierAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_SUPLIER_FAIL,
      });
    }
  };

export const updateSuplierAction =
  (id, name,phone,address) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: UPDATE_SUPLIER_REQUEST,
    });
    try {
      const resp = await api.put(
        "suplier/update/" + id,
        { name,phone,address },
        config
      );
      const { status, data } = resp;
      dispatch({
        type: UPDATE_SUPLIER_SUCCESS,
      });
      if (status == 200) {
        dispatch(getSuplierAction());
        dispatch(getSuplierAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been updated",
          timer: 1500,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_SUPLIER_FAIL,
      });
    }
  };
