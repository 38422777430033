import React from "react";
import { useSelector } from "react-redux";

const LabReport = ({ reports }) => {
  let totalSum = 0;
  let subTotalSum = 0;
  let totalDiscountPercent = 0;
  let totalDiscountMoney = 0;

  return (
    <table className=" mx-auto bg-white mt-5">
      <thead>
        <tr>
          <th className="py-2 border-b">#</th>
          <th className="py-2 border-b">Name</th>
          <th className="py-2 border-b">Discount</th>
          <th className="py-2 border-b">Discount %</th>
          <th className="py-2 border-b">Sub Total</th>
          <th className="py-2 border-b">Total</th>
          <th className="py-2 border-b">Date</th>
        </tr>
      </thead>
      <tbody>
        {reports && reports.invoices && reports.invoices.length > 0 ? (
          reports.invoices.map((report, index) => {
            // Initialize subTotal as a number
            let subTotal = Number(report.total);

            // Ensure discounts are numbers
            const discountMoney = Number(report.dicount_money) || 0;
            const discountPercent = Number(report.dicount_percent) || 0;

            // Calculate sub total
            if (discountMoney) {
                // totalPrice * (1 - discountPresentage / 100)
              subTotal = subTotal * (1 - discountMoney / 100);
              totalDiscountPercent += discountMoney;
            } else if (discountPercent) {
              subTotal = subTotal - discountPercent;
              totalDiscountMoney -= discountPercent;
            }

            // Accumulate totals
            totalSum += Number(report.total);
            subTotalSum += subTotal;

            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{report.patient.fullname}</td>
                <td>{discountPercent}</td>
                <td>{discountMoney}</td>
                <td>{subTotal.toFixed(2)}</td> {/* Display sub total */}
                <td>{report.total}</td>
                <td>{report.created_at.split("T")[0]}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="7">No invoices found.</td>
          </tr>
        )}

        <tr>
          <td>Totals:</td>

          <td>
            <p className="font-bold">Sub Total : {subTotalSum.toFixed(2)} </p>{" "}
          </td>
          <td>
            {" "}
            <p className="font-bold">Total : {totalSum.toFixed(2)} </p>{" "}
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

export default LabReport;
