export const CREATE_SUPLIER_REQUEST = "CREATE_SUPLIER_REQUEST";
export const CREATE_SUPLIER_SUCCESS = "CREATE_SUPLIER_SUCCESS";
export const CREATE_SUPLIER_FAIL = "CREATE_SUPLIER_FAIL";

export const FETCH_SUPLIER_REQUEST = "FETCH_SUPLIER_REQUEST";
export const FETCH_SUPLIER_SUCCESS = "FETCH_SUPLIER_SUCCESS";
export const FETCH_SUPLIER_FAIL = "FETCH_SUPLIER_FAIL";

export const UPDATE_SUPLIER_REQUEST = "UPDATE_SUPLIER_REQUEST";
export const UPDATE_SUPLIER_SUCCESS = "UPDATE_SUPLIER_SUCCESS";
export const UPDATE_SUPLIER_FAIL = "UPDATE_SUPLIER_FAIL";
