import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createPatientLabrotoryAction,
  updatePatientLabrotoryAction,
} from "../../../Actions/LabrotoryAction/PatientAction";

const SubmitButton = ({
  testValue,
  patientValue,
  result,
  test,
  price,
  reset,
  resetTest,
  invoice,
}) => {
  const dispatch = useDispatch();
  const updateInvoiceTest = useSelector((state) => state.updateInvoiceTest);
  const { updateInvoiceTests } = updateInvoiceTest;
  const handleSubmit = () => {
    if (patientValue.id) {
      let invoiceTestIds = [];
      if (updateInvoiceTests.length > 0) {
        updateInvoiceTests.map((test) => {
          invoiceTestIds.push(test.id);
        });
      } else {
        invoice?.invoice_test?.map((test) => {
          invoiceTestIds.push(test.id);
        });
      }

      dispatch(
        updatePatientLabrotoryAction(
          patientValue.id,
          patientValue.fullname,
          patientValue.phone,
          patientValue.birthDate,
          patientValue.gender,
          testValue,
          result,
          price,
          test,
          reset,
          resetTest,
          invoiceTestIds
        )
      );
      return;
    } else {
      dispatch(
        createPatientLabrotoryAction(
          patientValue.fullname,
          patientValue.phone,
          patientValue.birthDate,
          patientValue.gender,
          testValue,
          result,
          price,
          test,
          reset,
          resetTest
        )
      );
    }
  };
  return (
    <>
      <div className="ml-3">
        <button className="btn-info" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};

export default SubmitButton;
