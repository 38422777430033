import {
  CREATE_PURCHASE_FAIL,
  CREATE_PURCHASE_REQUEST,
  CREATE_PURCHASE_SUCCESS,
  SHOW_PURCHASE_FAIL,
  SHOW_PURCHASE_REQUEST,
  SHOW_PURCHASE_SUCCESS,
  UPDATE_PURCHASE_FAIL,
  UPDATE_PURCHASE_REQUEST,
  UPDATE_PURCHASE_SUCCESS,
} from "../../ActionType/LabrotoryTypes/PurchaseTypes";

export const createPurchaseReducer = (
  state = { loading: true, purchase: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_PURCHASE_REQUEST:
      return {
        loading: true,
      };
    case CREATE_PURCHASE_SUCCESS:
      return {
        loading: false,
        purchase: action.payload,
        error: "",
      };
    case CREATE_PURCHASE_FAIL:
      return {
        loading: false,
        purchase: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const updatePurchaseReducer = (
  state = { loading: true, purchase: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_PURCHASE_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_PURCHASE_SUCCESS:
      return {
        loading: false,
        purchase: action.payload,
        error: "",
      };
    case UPDATE_PURCHASE_FAIL:
      return {
        loading: false,
        purchase: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const showPurchaseReducer = (
  state = { loading: true, purchases: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_PURCHASE_REQUEST:
      return {
        loading: true,
      };
    case SHOW_PURCHASE_SUCCESS:
      return {
        loading: false,
        purchases: action.payload,
        error: "",
      };
    case SHOW_PURCHASE_FAIL:
      return {
        loading: false,
        purchases: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};
