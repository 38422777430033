export const CREATE_PURCHASE_REQUEST = "CREATE_PURCHASE_REQUEST";
export const CREATE_PURCHASE_SUCCESS = "CREATE_PURCHASE_SUCCESS";
export const CREATE_PURCHASE_FAIL = "CREATE_PURCHASE_FAIL";

export const FETCH_PURCHASE_REQUEST = "FETCH_PURCHASE_REQUEST";
export const FETCH_PURCHASE_SUCCESS = "FETCH_PURCHASE_SUCCESS";
export const FETCH_PURCHASE_FAIL = "FETCH_PURCHASE_FAIL";

export const SHOW_PURCHASE_REQUEST = "SHOW_PURCHASE_REQUEST";
export const SHOW_PURCHASE_SUCCESS = "SHOW_PURCHASE_SUCCESS";
export const SHOW_PURCHASE_FAIL = "SHOW_PURCHASE_FAIL";


export const UPDATE_PURCHASE_REQUEST = "UPDATE_PURCHASE_REQUEST";
export const UPDATE_PURCHASE_SUCCESS = "UPDATE_PURCHASE_SUCCESS";
export const UPDATE_PURCHASE_FAIL = "UPDATE_PURCHASE_FAIL";
