import React from 'react'

const ReprotByDoctor = ({byDoctor}) => {
  return (
    <table className=" mx-auto bg-white mt-5">
            <thead>
              <tr>
                <th className="py-2 border-b">#</th>
                <th className="py-2 border-b">Count</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>invoices Number </td>
                <td>{byDoctor?.invoiceCount}</td>
              </tr>
              <tr>
                <td>Male </td>
                <td>{byDoctor?.male}</td>
              </tr>
              <tr>
                <td>Female </td>
                <td>{byDoctor?.female}</td>
              </tr>
              <tr>
                <td>Total Price </td>
                <td >{byDoctor?.totalPrice}</td>
              </tr>
            </tbody>
          </table>
  )
}

export default ReprotByDoctor