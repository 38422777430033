import React, { useEffect, useState } from "react";
import { MdOutlineSaveAlt } from "react-icons/md";
import { useDispatch } from "react-redux";
import { createExpenseAction, updateExpenseAction } from "../../Actions/LabrotoryAction/ExpenseAction";

const CreateExpenseModal = ({ userId, onClose, expenseList }) => {
  const [inputValue, setInputValue] = useState({
    id: "",
    amount: "",
    reason: "",
  });
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };
  useEffect(() => {
    if (expenseList?.length > 0) {
      expenseList?.map((expense) => {
        setInputValue({
          ...inputValue,
          id: expense.id,
          amount: expense.amount,
          reason: expense.resoun,
        });
      });
    } else {
      return;
    }
  }, [expenseList]);
  const validate = () => {
    const errors = {};
    if (!inputValue.amount) {
      errors.amount = "Amount is required";
    } else if (isNaN(inputValue.amount)) {
      errors.amount = "Amount must be a number";
    }

    if (!inputValue.reason) {
      errors.reason = "Reason is required";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();

    if (Object.keys(errors).length === 0) {
      if (inputValue.id) {
        dispatch(
          updateExpenseAction(inputValue.id,userId, inputValue.amount, inputValue.reason)
        );
        // Clear the form
        setInputValue({
          amount: "",
          reason: "",
        });
        onClose();
        setErrors({});
      } else {
        dispatch(
          createExpenseAction(userId, inputValue.amount, inputValue.reason)
        );
        // Clear the form
        setInputValue({
          amount: "",
          reason: "",
        });
        onClose();
        setErrors({});
      }
    } else {
      setErrors(errors);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <div>
            <label htmlFor="amount">Amount </label>
          </div>
          <input
            type="text"
            id="amount"
            className="input w-full"
            name="amount"
            value={inputValue.amount}
            onChange={handleChange}
          />
          {errors.amount && (
            <span className="text-red-500">{errors.amount}</span>
          )}
        </div>

        <div className="flex flex-col">
          <div>
            <label htmlFor="reason">Reason </label>
          </div>
          <input
            type="text"
            id="reason"
            className="input w-full"
            name="reason"
            value={inputValue.reason}
            onChange={handleChange}
          />
          {errors.reason && (
            <span className="text-red-500">{errors.reason}</span>
          )}
        </div>

        <button
          type="submit"
          className="btn-primary flex gap-2 items-center mt-3"
        >
          Save <MdOutlineSaveAlt />
        </button>
      </form>
    </div>
  );
};

export default CreateExpenseModal;
