export function getAge(dateString) {
    var nowDate = new Date(new Date().setHours(0, 0, 0, 0));
    // Example date of birth.
    var dobDate = new Date(dateString);
  
    var years = nowDate.getFullYear() - dobDate.getFullYear();
    var months = nowDate.getMonth() - dobDate.getMonth();
    var days = nowDate.getDate() - dobDate.getDate();

    // Work out the difference in months.
    months += years * 12;
    if (days < 0) {
      months -= 1;
    }
    // Now add those months to the date of birth.
    dobDate.setMonth(dobDate.getMonth() + months);
    // Calculate the difference in milliseconds.
    let diff = nowDate - dobDate;
    // Divide that by 86400 to get the number of days.
    var days = Math.round(diff / 86400 / 1000);
    // Now convert months back to years and months.
    years = parseInt(months / 12);
    months -= years * 12;

    let text = "";
    if (years) {
      text = years + (years > 1 ? " years" : " year");
    }
    if (months) {
      if (text.length) {
        text = text + ", ";
      }
      text = text + months + (months > 1 ? " months" : " month");
    }
    if (days) {
      if (text.length) {
        text = text + ", ";
      }
      text = text + days + (days > 1 ? " days" : " day");
    }
    if (nowDate === dobDate) {
      text = "Newborn";
    }
    if (
      text === "" ||
      text === null ||
      text === undefined ||
      days < 0 ||
      months < 0 ||
      years < 0
    ) {
      text = "age can not be calculated";
    }
    

    return text;
  }



  export const getOnlyAge = (dateString) => {
    const nowDate = new Date();
    const dobDate = new Date(dateString);
  
    let age = nowDate.getFullYear() - dobDate.getFullYear();
    const monthDifference = nowDate.getMonth() - dobDate.getMonth();
  
    // Check if the birthday has not occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && nowDate.getDate() < dobDate.getDate())) {
      age--;
    }
  
    return age;
  };