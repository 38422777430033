export const CREATE_EXPENSE_REQUEST = "CREATE_EXPENSE_REQUEST";
export const CREATE_EXPENSE_SUCCESS = "CREATE_EXPENSE_SUCCESS";
export const CREATE_EXPENSE_FAIL = "CREATE_EXPENSE_FAIL";

export const FETCH_EXPENSE_REQUEST = "FETCH_EXPENSE_REQUEST";
export const FETCH_EXPENSE_SUCCESS = "FETCH_EXPENSE_SUCCESS";
export const FETCH_EXPENSE_FAIL = "FETCH_EXPENSE_FAIL";

export const UPDATE_EXPENSE_REQUEST = "UPDATE_EXPENSE_REQUEST";
export const UPDATE_EXPENSE_SUCCESS = "UPDATE_EXPENSE_SUCCESS";
export const UPDATE_EXPENSE_FAIL = "UPDATE_EXPENSE_FAIL";
