import Swal from "sweetalert2";
import {
  CREATE_INVOICE_FAIL,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
  FETCH_INVOICE_FAIL,
  FETCH_INVOICE_REQUEST,
  FETCH_INVOICE_SUCCESS,
  UPDATE_INVOICE_TEST_FAIL,
  UPDATE_INVOICE_TEST_REQUEST,
  UPDATE_INVOICE_TEST_SUCCESS,
} from "../../ActionType/LabrotoryTypes/InvoiceTypes";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const getInvoiceAction =
  (page, perPage, patient_name, patient_code, from, to, testId, doctorId) =>
  async (dispatch) => {
    console.log(page, perPage, patient_name, patient_code, from, to, testId, doctorId)
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page,
        perPage,
        patient_name,
        patient_code,
        from,
        to,
        testId,
        doctorId,
      },
    };
    dispatch({
      type: FETCH_INVOICE_REQUEST,
    });
    try {
      const resp = await api.get("invoice", config);
      const { status, data } = resp;

      if (status == 200) {
        dispatch({
          type: FETCH_INVOICE_SUCCESS,
          payload: { data: data.data, total: data.total },
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_INVOICE_FAIL,
      });
    }
  };

export const createInvoiceAction =
  (
    patient_id,
    doctor_id,
    total,
    dicount_percent,
    dicount_money,
    result,
    testIds,
    resetTest
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_INVOICE_REQUEST,
    });
    try {
      const resp = await api.post(
        "invoice/create",
        { patient_id, doctor_id, total, dicount_percent, dicount_money },
        config
      );
      const { status, data } = resp;
      dispatch({
        type: CREATE_INVOICE_SUCCESS,
      });
      if (status == 201) {
        resetTest();
        let testId = [];
        testIds.map((test) => {
          testId.push(test.value);
        });
        dispatch(createInvoiceTestAction(data.id, testId, result));

        // Swal.fire({
        //   position: "center",
        //   icon: "success",
        //   title: "Your work has been saved",

        //   timer: 1500,
        // });
      }
    } catch (error) {
      dispatch({
        type: CREATE_INVOICE_FAIL,
      });
    }
  };

export const updateInvoiceAction =
  (
    id,
    patient_id,
    doctor_id,
    total,
    dicount_percent,
    dicount_money,
    result,
    testIds,
    resetTest,
    invoiceTestIds
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_INVOICE_REQUEST,
    });
    try {
      const resp = await api.put(
        "invoice/update/" + id,
        { patient_id, doctor_id, total, dicount_percent, dicount_money },
        config
      );
      const { status, data } = resp;
      dispatch({
        type: CREATE_INVOICE_SUCCESS,
      });
      if (status == 201) {
        // resetTest();
        let testId = [];
        testIds.map((test) => {
          testId.push(test.value);
        });
        dispatch(updateInvoiceTestAction(invoiceTestIds, id, testId, result));
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: CREATE_INVOICE_FAIL,
      });
    }
  };

export const createInvoiceTestAction =
  (invoice_id, test_id, result) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_INVOICE_REQUEST,
    });
    try {
      const resp = await api.post(
        "invoice_test/create",
        { invoice_id, test_id, result },
        config
      );
      const { status, data } = resp;
      dispatch({
        type: CREATE_INVOICE_SUCCESS,
      });

      if (status == 201) {
        // Swal.fire({
        //   position: "center",
        //   icon: "success",
        //   title: "Your work has been saved",
        //   timer: 1500,
        // });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: CREATE_INVOICE_FAIL,
      });
    }
  };

export const updateInvoiceTestAction =
  (ids, invoice_id, test_id, result) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: UPDATE_INVOICE_TEST_REQUEST,
    });
    try {
      const resp = await api.post(
        "invoice_test/updateAll",
        { ids, invoice_id, test_id, result },
        config
      );
      const { status, data } = resp;

      dispatch({
        type: UPDATE_INVOICE_TEST_SUCCESS,
        payload: data,
      });

      if (status == 201) {
        // Swal.fire({
        //   position: "center",
        //   icon: "success",
        //   title: "Your work has been saved",
        //   timer: 1500,
        // });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: UPDATE_INVOICE_TEST_FAIL,
      });
    }
  };

export const updateInvoiceResultTestAction =
  (ids, result) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_INVOICE_REQUEST,
    });
    try {
      const resp = await api.post(
        "invoice_test/update",
        { ids, result },
        config
      );
      const { status, data } = resp;
      dispatch({
        type: CREATE_INVOICE_SUCCESS,
      });

      if (status == 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been update",
          timer: 1500,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: CREATE_INVOICE_FAIL,
      });
    }
  };
