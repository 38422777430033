export const CREATE_INVOICE_REQUEST = "CREATE_INVOICE_REQUEST";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAIL = "CREATE_INVOICE_FAIL";

export const FETCH_INVOICE_REQUEST = "FETCH_INVOICE_REQUEST";
export const FETCH_INVOICE_SUCCESS = "FETCH_INVOICE_SUCCESS";
export const FETCH_INVOICE_FAIL = "FETCH_INVOICE_FAIL";

export const UPDATE_INVOICE_REQUEST = "UPDATE_INVOICE_REQUEST";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAIL = "UPDATE_INVOICE_FAIL";


export const UPDATE_INVOICE_TEST_REQUEST = "UPDATE_INVOICE_TEST_REQUEST";
export const UPDATE_INVOICE_TEST_SUCCESS = "UPDATE_INVOICE_TEST_SUCCESS";
export const UPDATE_INVOICE_TEST_FAIL = "UPDATE_INVOICE_TEST_FAIL";
