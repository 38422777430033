import Swal from "sweetalert2";
import {
  CREATE_MANGE_TEST_FAIL,
  CREATE_MANGE_TEST_REQUEST,
  CREATE_MANGE_TEST_SUCCESS,
  CREATE_TEST_CATEGORY_FAIL,
  CREATE_TEST_CATEGORY_REQUEST,
  CREATE_TEST_CATEGORY_SUCCESS,
  DELETE_TEST_CATEGORY_FAIL,
  DELETE_TEST_CATEGORY_REQUEST,
  DELETE_TEST_CATEGORY_SUCCESS,
  FETCH_MANGE_TEST_CATEGORY_SUCCESS,
  FETCH_MANGE_TEST_FAIL,
  FETCH_MANGE_TEST_REQUEST,
  FETCH_MANGE_TEST_SUCCESS,
  FETCH_TEST_CATEGORY_FAIL,
  FETCH_TEST_CATEGORY_REQUEST,
  FETCH_TEST_CATEGORY_SUCCESS,
  FETCH_TEST_FAIL,
  FETCH_TEST_REQUEST,
  FETCH_TEST_SUCCESS,
} from "../../ActionType/LabrotoryTypes/TestType";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const getTestAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: FETCH_TEST_REQUEST,
  });
  try {
    const resp = await api.get("test", config);
    const { status, data } = resp;

    if (status == 200) {
      dispatch({
        type: FETCH_TEST_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_TEST_FAIL,
      payload: [],
    });
    if (error.response.status == 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
  }
};

export const getTestCategoryAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: FETCH_TEST_CATEGORY_REQUEST,
  });
  try {
    const resp = await api.get("testCategory", config);
    const { status, data } = resp;
    console.log(data);
    if (status == 200) {
      dispatch({
        type: FETCH_TEST_CATEGORY_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_TEST_CATEGORY_FAIL,
      payload: [],
    });
    if (error.response.status == 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
  }
};

export const deleteTestCategoryAction = (id) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: DELETE_TEST_CATEGORY_REQUEST,
  });
  try {
    const resp = await api.delete("testCategory/delete/" + id, config);
    const { status, data } = resp;

    if (status == 200) {
      dispatch(getTestCategoryAction());
      dispatch(getTestCategoryAction());
      dispatch({
        type: DELETE_TEST_CATEGORY_SUCCESS,
        payload: data,
      });
      Swal.fire({
        icon: "success",
        title: "Good Job ",
        text: "Successfuly Delete Item",
        timer: 2000,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_TEST_CATEGORY_FAIL,
      payload: [],
    });
    if (error.response.status == 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
  }
};

export const createTestCategoryAction = (test) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: CREATE_TEST_CATEGORY_REQUEST,
  });
  try {
    const resp = await api.post("testCategory/create", { name: test }, config);
    const { status, data } = resp;
    if (status == 201) {
      dispatch(getTestCategoryAction());
      dispatch(getTestCategoryAction());
      dispatch({
        type: CREATE_TEST_CATEGORY_SUCCESS,
        payload: data,
      });
      Swal.fire({
        icon: "success",
        title: "Good Job ",
        text: "Your work has been saved",
        timer: 2000,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_TEST_CATEGORY_FAIL,
      payload: [],
    });
    if (error.response.status == 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
  }
};

export const createMangeTestAction =
  (name, test_category_id, unit, price, cost, instrument, normal_range) =>
  async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_MANGE_TEST_REQUEST,
    });
    try {
      const resp = await api.post(
        "mangeTest/create",
        { name, test_category_id, unit, price, cost, instrument, normal_range },
        config
      );
      const { status, data } = resp;
      if (status == 201) {
        dispatch(getMangeTestAction());
        dispatch(getMangeTestAction());
        dispatch({
          type: CREATE_MANGE_TEST_SUCCESS,
          payload: data,
        });
        Swal.fire({
          icon: "success",
          title: "Good Job ",
          text: "Your work has been saved",
          timer: 2000,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_MANGE_TEST_FAIL,
        payload: [],
      });
      if (error.response.status == 401) {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  };

export const updateMangeTestAction =
  (id, name, test_category_id, unit, price, cost, instrument, normal_range) =>
  async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: CREATE_MANGE_TEST_REQUEST,
    });
    try {
      const resp = await api.put(
        "mangeTest/update/" + id,
        { name, test_category_id, unit, price, cost, instrument, normal_range },
        config
      );
      const { status, data } = resp;
      if (status == 200) {
        dispatch(getMangeTestAction());
        dispatch(getMangeTestAction());
        dispatch({
          type: CREATE_MANGE_TEST_SUCCESS,
          payload: data,
        });
        Swal.fire({
          icon: "success",
          title: "Good Job ",
          text: "Your work has been Updated",
          timer: 2000,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_MANGE_TEST_FAIL,
        payload: [],
      });
      if (error.response.status == 401) {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  };

export const getMangeTestAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: FETCH_MANGE_TEST_REQUEST,
  });
  try {
    const resp = await api.get("mangeTest", config);
    const { status, data } = resp;
    if (status == 200) {
      dispatch({
        type: FETCH_MANGE_TEST_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_MANGE_TEST_FAIL,
      payload: [],
    });
    if (error.response.status == 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
  }
};
