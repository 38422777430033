import {
    FETCH_LAB_REPORT_FAIL,
    FETCH_LAB_REPORT_PROFIT_FAIL,
    FETCH_LAB_REPORT_PROFIT_REQUEST,
    FETCH_LAB_REPORT_PROFIT_SUCCESS,
    FETCH_LAB_REPORT_SUCCESS,
  FETCH_REPORT_BY_DOCTOR_FAIL,
  FETCH_REPORT_BY_DOCTOR_SUCCESS,
  FETCH_REPORT_BY_TEST_FAIL,
  FETCH_REPORT_BY_TEST_SUCCESS,
} from "../../ActionType/LabrotoryTypes/LabReportType";
import { api } from "../../Api/api";
import { getToken } from "../login";


export const getLabReportAction =
  (from, to) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        from,
        to,
        // doctor_id,
      },
    };
    // dispatch({
    //   type: FETCH_INVOICE_REQUEST,
    // });
    try {
      const resp = await api.get("lab/report/report", config);
      const { status, data } = resp;

      if (status == 200) {
        dispatch({
          type: FETCH_LAB_REPORT_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_LAB_REPORT_FAIL,
      });
    }
  };
export const getLabReportByDoctorAction =
  (from, to, doctor_id) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        from,
        to,
        doctor_id,
      },
    };
    // dispatch({
    //   type: FETCH_INVOICE_REQUEST,
    // });
    try {
      const resp = await api.get("lab/report/byDoctor", config);
      const { status, data } = resp;

      if (status == 200) {
        dispatch({
          type: FETCH_REPORT_BY_DOCTOR_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_REPORT_BY_DOCTOR_FAIL,
      });
    }
  };

export const getLabReportByTestAction =
  (from, to, test_id) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        from,
        to,
        test_id,
      },
    };
    
    try {
      const resp = await api.get("lab/report/byTest", config);
      const { status, data } = resp;

      if (status == 200) {
        dispatch({
          type: FETCH_REPORT_BY_TEST_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_REPORT_BY_TEST_FAIL,
      });
    }
  };

  export const getLabReportByProfitAction =
  (from, to) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        from,
        to,
        
      },
    };
    
    try {
      const resp = await api.get("lab/report/profit", config);
      const { status, data } = resp;

      if (status == 200) {
        dispatch({
          type: FETCH_LAB_REPORT_PROFIT_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_LAB_REPORT_PROFIT_FAIL,
      });
    }
  };
