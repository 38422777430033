import React from 'react'

const ModalPurchasePayback = ({onClose,head,children}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">{head}</h2>
        <button onClick={onClose}>&times;</button>
      </div>
      {children}
    </div>
  </div>
  )
}

export default ModalPurchasePayback