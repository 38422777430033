import React from "react";

const ViewPatientInfo = ({ patient }) => {
    const calculateAdjustedTotal = () => {
        let adjustedTotal = patient.total; // Initialize with the original total
    
        if (patient.dicount_money) {
          const discountAmount = (patient.dicount_money / 100) * patient.total;
          adjustedTotal -= discountAmount;
        } else if (patient.dicount_percent) {
          adjustedTotal -= patient.dicount_percent;
        }
    
        return adjustedTotal;
      };
  return (
    <div>
      <table>
        <thead className="">
          <tr>
            <th>Code</th>
            <th>Full Name</th>

            <th>Phone</th>
            <th>Total</th>
            <th>Discount</th>

            <th>Final </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{patient.patient.code}</td>
            <td>{patient.patient.fullname}</td>
            <td>{patient.patient.phone}</td>
            <td>{patient.total}</td>
            <td>
              {patient.dicount_percent
                ? patient.dicount_percent
                : patient.dicount_money}
            </td>
            <td>{calculateAdjustedTotal()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ViewPatientInfo;
