import React from "react";

const ReportByTest = ({ byTest }) => {
  return (
    <table className=" mx-auto bg-white mt-5">
      <thead>
        <tr>
          <th className="py-2 border-b">#</th>
          <th className="py-2 border-b">Count</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Test Used </td>
          <td>{byTest?.testUsed}</td>
        </tr>

        <tr>
          <td>Total Price </td>
          <td>{byTest?.totalPrice}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReportByTest;
