import React, { forwardRef } from "react";
import HeaderImage from "../../../Img/header.jpeg"; // Adjust path as per your project structure
import FooterImage from "../../../Img/footer.jpeg"; // Adjust path as per your project structure
import "./style_print.css";
import { getOnlyAge } from "../../../Actions/getAge";

const PrintPageLab = forwardRef(({ invoices }, ref) => {
  const createPageBreak = () => (
    <React.Fragment>
      <div className="page-break" />
      <div style={{ height: "250px" }} /> {/* Spacer to avoid overlap with header */}
    </React.Fragment>
  );
  console.log(invoices)
  const displayedCategories = [];

  // Function to create a table for patient info
  const createPatientInfoTable = () => (
    <table>
      <thead className="bg-indigo-500">
        <tr>
          <th className="bg-indigo-500">Name</th>
          <th className="bg-indigo-500">Phone</th>
          <th className="bg-indigo-500">Age</th>
          <th className="bg-indigo-500">Gender</th>
          <th className="bg-indigo-500">Referral</th>
          <th className="bg-indigo-500">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{invoices?.patient?.fullname}</td>
          <td>{invoices?.patient?.phone}</td>
          <td>{getOnlyAge(invoices?.patient?.birth_date)} Yr</td>
          <td>{invoices?.patient?.gender}</td>
          <td>{invoices?.dcotor?.name}</td>
          <td>{invoices?.patient?.created_at.split("T")[0]}</td>
        </tr>
      </tbody>
    </table>
  );

  // Function to create a table for category title and subTests
  const createSubTestsTable = (categoryName, subTests, startIndex) => (
    <React.Fragment>
      <div className="category-title">{categoryName}</div>
      <table className="border">
        <thead className="border bg-teal-500 text-white">
          <tr>
            
            <th className="text-white">Test Name</th>
            <th className="text-white">Result</th>
            <th className="text-white">Unit</th>
            <th className="text-white">Normal Range</th>
          </tr>
        </thead>
        <tbody>
          {subTests.map((subTest, tIndex) => (
            <React.Fragment key={startIndex + tIndex}>
              {startIndex + tIndex > 0 && (startIndex + tIndex) % 10 === 0 && createPageBreak()}
              <tr className="border">
                 
                <td className="border">{subTest.test.name}</td>
                <td className="border">{subTest.result}</td>
                <td className="border">{subTest.test.unit}</td>
                <td className="border">{subTest.test.normal_range}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );

  let currentRowCount = 0;

  return (
    <div ref={ref} className="printable">
      {/* Header */}
      <div className="header">
        <img src={HeaderImage} alt="Header" />
      </div>

      {/* Content */}
      <div className="table-container">
        {createPatientInfoTable()}
        {invoices?.invoice_test?.reduce((acc, test, index) => {
          if (!displayedCategories.includes(test.test.category.name)) {
            displayedCategories.push(test.test.category.name);
            const subTests = invoices.invoice_test.filter(
              subTest =>
                subTest.test.category.name === test.test.category.name
            );

            if (currentRowCount + subTests.length > 10) {
              const remainingRows = 10 - currentRowCount;
              const firstBatch = subTests.slice(0, remainingRows);
              const secondBatch = subTests.slice(remainingRows);

              acc.elements.push(
                <div key={`${index}-first`} className="category-break">
                  {createSubTestsTable(test.test.category.name, firstBatch, currentRowCount)}
                </div>
              );

              acc.elements.push(createPageBreak());

              currentRowCount = secondBatch.length;
              acc.elements.push(
                <div key={`${index}-second`} className="category-break">
                  {createSubTestsTable(test.test.category.name, secondBatch, 0)}
                </div>
              );
            } else {
              acc.elements.push(
                <div key={index} className="category-break">
                  {createSubTestsTable(test.test.category.name, subTests, currentRowCount)}
                </div>
              );
              currentRowCount += subTests.length;
            }
          }
          return acc;
        }, { elements: [], currentRowCount: 0 }).elements}
      </div>

      {/* Footer */}
      <div className="footer">
        <img src={FooterImage} alt="Footer" />
      </div>
    </div>
  );
});

export default PrintPageLab;
