import {
    FETCH_LAB_REPORT_FAIL,
    FETCH_LAB_REPORT_PROFIT_FAIL,
    FETCH_LAB_REPORT_PROFIT_REQUEST,
    FETCH_LAB_REPORT_PROFIT_SUCCESS,
    FETCH_LAB_REPORT_REQUEST,
  FETCH_LAB_REPORT_SUCCESS,
  FETCH_REPORT_BY_DOCTOR_FAIL,
  FETCH_REPORT_BY_DOCTOR_REQUEST,
  FETCH_REPORT_BY_DOCTOR_SUCCESS,
  FETCH_REPORT_BY_TEST_FAIL,
  FETCH_REPORT_BY_TEST_REQUEST,
  FETCH_REPORT_BY_TEST_SUCCESS,
} from "../../ActionType/LabrotoryTypes/LabReportType";

export const getLabReportByDoctorReducer = (
  state = { loading: true, byDoctor: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_REPORT_BY_DOCTOR_REQUEST:
      return {
        loading: true,
      };
    case FETCH_REPORT_BY_DOCTOR_SUCCESS:
      return {
        loading: false,
        byDoctor: action.payload,
        error: "",
      };
    case FETCH_REPORT_BY_DOCTOR_FAIL:
      return {
        loading: false,
        byDoctor: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const getLabReportByTestReducer = (
  state = { loading: true, byTest: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_REPORT_BY_TEST_REQUEST:
      return {
        loading: true,
      };
    case FETCH_REPORT_BY_TEST_SUCCESS:
      return {
        loading: false,
        byTest: action.payload,
        error: "",
      };
    case FETCH_REPORT_BY_TEST_FAIL:
      return {
        loading: false,
        byTest: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const getLabReportReducer = (
  state = { loading: true, reports: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_LAB_REPORT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_LAB_REPORT_SUCCESS:
      return {
        loading: false,
        reports: action.payload,
        error: "",
      };
    case FETCH_LAB_REPORT_FAIL:
      return {
        loading: false,
        reports: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};


export const getLabReportProfitReducer = (
  state = { loading: true, profits: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_LAB_REPORT_PROFIT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_LAB_REPORT_PROFIT_SUCCESS:
      return {
        loading: false,
        profits: action.payload,
        error: "",
      };
    case FETCH_LAB_REPORT_PROFIT_FAIL:
      return {
        loading: false,
        profits: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};