export const CREATE_PAYBACK_REQUEST = "CREATE_PAYBACK_REQUEST";
export const CREATE_PAYBACK_SUCCESS = "CREATE_PAYBACK_SUCCESS";
export const CREATE_PAYBACK_FAIL = "CREATE_PAYBACK_FAIL";

export const FETCH_PAYBACK_REQUEST = "FETCH_PAYBACK_REQUEST";
export const FETCH_PAYBACK_SUCCESS = "FETCH_PAYBACK_SUCCESS";
export const FETCH_PAYBACK_FAIL = "FETCH_PAYBACK_FAIL";

export const SHOW_PAYBACK_REQUEST = "SHOW_PAYBACK_REQUEST";
export const SHOW_PAYBACK_SUCCESS = "SHOW_PAYBACK_SUCCESS";
export const SHOW_PAYBACK_FAIL = "SHOW_PAYBACK_FAIL";


export const UPDATE_PAYBACK_REQUEST = "UPDATE_PAYBACK_REQUEST";
export const UPDATE_PAYBACK_SUCCESS = "UPDATE_PAYBACK_SUCCESS";
export const UPDATE_PAYBACK_FAIL = "UPDATE_PAYBACK_FAIL";
