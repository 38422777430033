import { FETCH_SUPLIER_FAIL, FETCH_SUPLIER_REQUEST, FETCH_SUPLIER_SUCCESS } from "../../ActionType/LabrotoryTypes/SuplierTupe";

export const getSuplierReducer = (
  state = { loading: true, supliers: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_SUPLIER_REQUEST:
      return {
        loading: true,
      };
    case FETCH_SUPLIER_SUCCESS:
      return {
        loading: false,
        supliers: action.payload,
        error: "",
      };
    case FETCH_SUPLIER_FAIL:
      return {
        loading: false,
        supliers: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};
