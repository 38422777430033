// Import React and necessary Tailwind CSS classes
import React from 'react';

const Modal = ({ isOpen, onClose, children,head }) => {
  // Conditional rendering of modal based on isOpen prop
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 w-full flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 w-full bg-gray-900 opacity-50"></div>
      
      {/* Modal dialog */}
      <div className="bg-white z-50 rounded-lg overflow-hidden shadow-xl  lg:w-7/12">
        {/* Modal header */}
        <div className="bg-gray-100 px-4 py-2 border-b">
          <h2 className="text-lg font-semibold text-gray-800">{head}</h2>
        </div>
        
        {/* Modal body */}
        <div className="p-4 w-full">{children}</div>
        
        {/* Modal footer */}
        <div className="bg-gray-100 px-4 py-2 border-t flex justify-end">
          <button
            className="text-sm text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
