import React, { useState } from "react";
import { MdOutlineSaveAlt } from "react-icons/md";
import { useDispatch } from "react-redux";
import { createTestCategoryAction } from "../../../Actions/LabrotoryAction/TestAction";

const CreateTestCategoryModal = ({ onClose }) => {
  const [test, setTest] = useState(null);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (test == null || test == undefined || test == "") {
      setError("Please Fill The Test Gap");
      return;
    } else {
      dispatch(createTestCategoryAction(test));
      onClose();

      return;
    }
  };
  return (
    <div>
      <form action="" onSubmit={handleSubmit}>
        <input
          type="text"
          className="input w-full"
          onChange={(e) => {
            setTest(e.target.value);
          }}
          value={test}
          placeholder="Test Category"
        />
        <p className="text-red-500">{error && error}</p>
        <button
          type="submit"
          className="btn-info mt-2  flex gap-2 items-center"
        >
          Save <MdOutlineSaveAlt />
        </button>
      </form>
    </div>
  );
};

export default CreateTestCategoryModal;
