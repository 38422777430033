import React, { useEffect, useState } from "react";
import { LuBadgeDollarSign } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import CreateModals from "../../../Modal/CreateModals";
import CreateExpenseModal from "../../../Modal/LabrotoryModal/CreateExpenseModal";
import { CiEdit } from "react-icons/ci";
import { getExpenseAction } from "../../../Actions/LabrotoryAction/ExpenseAction";

const ExpenseScreen = ({ user }) => {
  const dispatch = useDispatch();
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);

  const getExpense = useSelector((state) => state.getExpense);
  const { expenses } = getExpense;

  const [expenseList, setExpenseList] = useState([]);
  const addExpenseToList = (test) => {
    setExpenseList((prevList) => {
      if (Array.isArray(prevList)) {
        return [...prevList, test];
      }
      return [test];
    });
  };
  useEffect(() => {
    dispatch(getExpenseAction());
    // dispatch(getMangeTestAction());
  }, []);
  console.log(expenses);
  return (
    <div className="parent p-2 ">
      <div className="flex gap-2 items-center justify-between">
        <div className="flex gap-2 items-center text-blue-800 font-bold text-lg">
          <div>
            <LuBadgeDollarSign />
          </div>
          <p>Expense</p>
        </div>

        <div>
          <button
            onClick={() => setIsOpenCreateModal(true)}
            className="btn-primary flex gap-2 items-center "
          >
            Add Expense <LuBadgeDollarSign />
          </button>
        </div>
      </div>

      <table className="mt-2">
        <thead className="">
          <tr>
            <th>#</th>
            <th>User</th>
            <th>amount</th>
            <th>Reason</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {expenses?.map((expense, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{expense.user.name}</td>
                <td>{expense.amount}</td>
                <td>{expense.resoun}</td>

                <td>
                  <div
                    onClick={() => {
                      setIsOpenCreateModal(true);
                      addExpenseToList(expense);
                    }}
                    className="flex gap-2 items-center"
                  >
                    <button className="btn-info flex gap-2 items-center  ">
                      update <CiEdit />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <CreateModals
        isOpen={isOpenCreateModal}
        onClose={() => {
          setIsOpenCreateModal(false);
          setExpenseList([]);
        }}
        head={expenseList.length > 0 ? "Update Expense" : "Add Expense"}
      >
        <CreateExpenseModal
          userId={user.id}
          onClose={() => setIsOpenCreateModal(false)}
          expenseList={expenseList}
        />
      </CreateModals>
    </div>
  );
};

export default ExpenseScreen;
